export class CalendarHelper {
    static build() {
        return new CalendarHelper();
    }

    public getAvailableYears() {
        const years = [];
        const currentYear = new Date().getFullYear();
        for (let year = currentYear; year >= currentYear - 150; year--) {
            years.push(year);
        }
        return years;
    }

    public getAvailableMonths() {
        const months = [];
        for (let month = 1; month <= 12; month++) {
            months.push(month);
        }
        return months;
    }

    public getAvailableDays() {
        const days = [];
        for (let day = 1; day <= 31; day++) {
            days.push(day);
        }
        return days;
    }

    public getFullDate(year: number, month: number, day: number) {
        const normalizedMonth = month < 10 ? `0${month}` : `${month}`;
        const normalizedDay = day < 10 ? `0${day}` : `${day}`;
        return `${year}-${normalizedMonth}-${normalizedDay}`;
    }

}