import React from "react";
import { Avatar, Box, Button, Card, CardBody, CardHeader, Flex, FormLabel, Grid, GridItem, Heading, Icon, Input, LinkBox, LinkOverlay, Spacer, Text } from "@chakra-ui/react";
import { FaArrowCircleRight } from "react-icons/fa";
import GradientBorder from "../../../../components/GradientBorder";
import DateDisplayField from "../../../../components/Forms/DateDisplayField";
import { UserDashboardResponseModel } from "../../../../modules/models/responses/user/user-dashboard-response.model";
import { useSelector } from "react-redux";
import { selectUserProfileDashboardSelector } from "../../../../modules/models/selectors/userProfileSelector";

const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];


export default function ProfileTabView() {
    const dashboard: UserDashboardResponseModel = useSelector(selectUserProfileDashboardSelector)
    const name = dashboard.userProfile?.name + " " + dashboard.userProfile?.surname;
    const firstName = dashboard.userProfile?.name ?? "";
    const lastName = dashboard.userProfile?.surname ?? "";

    const date = new Date(dashboard.userProfile?.birthDate ?? new Date());

    return (
        <Card maxW='md' bg={"linear-gradient(to right, #252799, #712be2)"} color="white">
            <CardHeader>
                <Flex>
                    <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                        <Avatar name={name} size="lg" src={dashboard.userProfile?.profilePictureUrl} />

                        <Box>
                            <Heading size='sm'>{dashboard.userProfile?.userName}</Heading>
                            <Text>{name}</Text>
                        </Box>
                    </Flex>
                </Flex>
            </CardHeader>
            <CardBody mt={0} pt={0}>
                <LinkBox as='article' maxW='sm' rounded='md' px={0}>
                    <Flex>
                        <LinkOverlay href='#'>
                            <Text className="form-container-heading" style={{
                                marginTop: "8px"
                            }}>Your Profile</Text>
                        </LinkOverlay>
                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            Edit
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>

                <FormLabel className={`form-label`}>
                    Username
                </FormLabel>
                <GradientBorder
                    className={`form-input-gradient-border`}>

                    <Input
                        readOnly={true}
                        type='text'
                        value={dashboard.userProfile?.userName ?? ""}
                        className="form-input"
                        placeholder="Username" />
                </GradientBorder>
                <Grid templateColumns="repeat(2, 1fr)" gap={2} width={"100%"} my={4}>
                    <GridItem colSpan={1}>
                        <FormLabel className={`form-label`}>
                            Name
                        </FormLabel>
                        <GradientBorder
                            className={`form-input-gradient-border`}>

                            <Input
                                readOnly={true}
                                type='text'
                                value={firstName}
                                className="form-input"
                                placeholder="Name" />
                        </GradientBorder>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <FormLabel className={`form-label`}>
                            Last Name
                        </FormLabel>
                        <GradientBorder
                            className={`form-input-gradient-border`}>

                            <Input
                                readOnly={true}
                                type='text'
                                value={lastName}
                                className="form-input"
                                placeholder="Last Name" />



                        </GradientBorder>
                    </GridItem>
                </Grid>

                <DateDisplayField
                    day={String(date?.getDay() ?? 0)}
                    month={monthNames[date.getMonth() ?? 0]}
                    year={String(date.getFullYear() ?? 0)}
                />
            </CardBody>
        </Card>
    )
}