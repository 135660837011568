import React from "react";
import {
    Box,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper
} from '@chakra-ui/react'
import { useTranslation } from "react-i18next";

export class ProgressStep {
    title: string;
    description: string;
    constructor(title: string, description: string) {
        this.title = title;
        this.description = description;
    }
}

export default function SignUpProgress({ index, steps }: { index: number, steps: ProgressStep[] }) {
    const { t } = useTranslation();
    return (
        <Stepper index={index} colorScheme='blue' orientation='horizontal' gap='8' mb={4}>
            {steps.map((step, index) => (
                <Step key={index}>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>

                    <Box flexShrink='0'>
                        <StepTitle style={{
                            fontWeight: "bold",
                        }}>{t(step.title)}</StepTitle>
                        <StepDescription style={{
                            color: "white"
                        }}>{t(step.description)}</StepDescription>
                    </Box>

                    <StepSeparator />
                </Step>
            ))
            }
        </Stepper >
    )
}