import { Dispatch } from "react";
import { SignUpEmailVerificationRequestModel } from "../../models/requests/authorization/sign-up/signUpEmailVerificationRequest.model";
import { SignUpEmailVerificationResponseModel } from "../../models/responses/authorization/sign-up/signUpEmailVerificationResponse.model";
import { HttpValidatorService } from "../http-validator.service";
import { SignUpService } from "./sign-up.service";
import { onFinishRegistration, updatePostVerificationEmail, updateVerificationEmailAction } from "../../stores/userRegistrationSlice";
import { RefreshActionRequestModel } from "../../models/requests/refreshActionRequest.model";
import { RefreshActionResponseModel } from "../../models/responses/refreshActionResponse.model";
import { SignUpSetPasswordRequestModel } from "../../models/requests/authorization/sign-up/signUpSetPassword.model";
import { BaseUserResponseModel } from "../../models/responses/baseUserResponse.model";

export class SignUpHandlerService {

    static build() {
        return new SignUpHandlerService();
    }

    verifyEmail(values: any, dispatch: Dispatch<any>, toast: any, handle: () => void = () => { }) {
        const request: SignUpEmailVerificationRequestModel = {
            ...values
        };

        SignUpService.build().verifyEmail(request).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: SignUpEmailVerificationResponseModel) => {
                console.log(data);
                dispatch(updatePostVerificationEmail(data))
                handle();
            }, () => {
                console.log("ERROR")
                handle();
            }, dispatch)
        }, (err: any) => {
            console.log("ERRPR", err)
            handle();
        });
    }

    resendVerificationEmail(values: any, dispatch: Dispatch<any>, toast: any) {
        const request: RefreshActionRequestModel = {
            actionId: values.actionId,
        };

        SignUpService.build().resendVerificationEmail(request).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: RefreshActionResponseModel) => {
                console.log(data);
                dispatch(updateVerificationEmailAction(data.newActionId))
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }, (err: any) => {
            console.log("ERRPR", err)
        });
    }

    setPassword(values: any, dispatch: Dispatch<any>, toast: any) {
        const request: SignUpSetPasswordRequestModel = {
            ...values
        };

        SignUpService.build().setPassword(request).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: BaseUserResponseModel) => {
                console.log(data);
                dispatch(onFinishRegistration())
            }, () => {
                console.log("ERROR")
            }, dispatch)
        });
    }

}