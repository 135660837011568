import React from "react";
import { Box, Heading, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function Home() {
    const { t, i18n, ready } = useTranslation();

    return (
        <Box>
            <Heading as="h1" size="2xl">{t("home", "Home")}</Heading>

        </Box>
    );
}