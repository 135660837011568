import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Text, Button, Container } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import '../../../styles/forms.scss';
import { Trans, useTranslation } from "react-i18next";

export default function RecoverPasswordEmailSent(props: {
    email: string
}) {
    const { t } = useTranslation();
    return (
        <Container
            className="form-flex-containter"
            centerContent
            py="8"
            pb={8}
            my={16}>
            <Box color="white" px={8} >
                <Box
                    flexWrap="wrap"
                    justifyContent="center"
                    alignItems="center"
                    width={"100%"}>
                    <Text className="form-container-heading" textAlign={"center"}>{t('success', 'Success')}</Text>
                    <Text textAlign={"center"} mb={4} fontWeight={"bold"}>
                        <CheckCircleIcon color="green.500" fontSize="1.5rem" mb={0} mx={2} /> {t('recovery_code_sent', 'Recovery code has been successfully sent.')}
                    </Text>
                    <Text textAlign={"center"} mb={8}>
                        <Trans
                            i18nKey="recovery_email_on_way"
                            components={{
                                1: <strong />
                            }}
                            values={{
                                email: props.email
                            }}
                            defaultValue={"A recovery email is on its way to <1>{{email}}</1>!"}
                        />
                    </Text>

                    <Button
                        variant="solid"
                        className="form-button"
                        py={6}
                        as={RouterLink} to="/sign-in">
                        {t('sign_in', 'Sign In')}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}