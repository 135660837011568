import * as Yup from 'yup';
import { codeYupGenerator } from '../../../responses/user/user-register-totp-response.model';

export const verificationCodeValidationSchema = () => Yup.object().shape({
    code: codeYupGenerator()
})

export class SignUpEmailVerificationRequestModel {
    public registrationId: string = '';
    public actionId: string = '';
    public code: string = '';
}