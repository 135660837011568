import axios from "axios";
import { getDefaultIdentityApiUrlWithVersion } from "../general-settings.service";
import { UserVerifyTotpRequestModel } from "../../models/requests/authorization/user/user-verify-totp-request.model";
import { UserSetAntiPhishingTokenRequestModel } from "../../models/requests/authorization/user/user-set-ani-phishing-token-request.model";
import { VerifyActionRequestModel } from "../../models/requests/authorization/verifyActionRequest.model";

export class UserSecurityService {
    static build() {
        return new UserSecurityService();
    }

    userSecurityInfo() {
        return axios.get(`${getDefaultIdentityApiUrlWithVersion()}/user/security/info`);
    }

    registerTotp() {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/user/security/register-totp`);
    }

    verifyTotp(request: UserVerifyTotpRequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/user/security/verify-totp`, request);
    }

    getEmail(emailUUID: string) {
        return axios.get(`${getDefaultIdentityApiUrlWithVersion()}/user/security/email?emailUUID=` + emailUUID);
    }

    getEmails() {
        return axios.get(`${getDefaultIdentityApiUrlWithVersion()}/user/security/emails`);
    }

    setAntiPhishingToken(request: UserSetAntiPhishingTokenRequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/user/security/set-anti-phishing-token`, request);
    }

    verifyAntiPhishingToken(request: VerifyActionRequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/user/security/verify-anti-phishing-token`, request);
    }

    refreshVerificationAction(actionUUID: string) {
        return axios.get(`${getDefaultIdentityApiUrlWithVersion()}/user/security/anti-phishing-token/refresh-verify?actionUUID=` + actionUUID);
    }
}