import axios from "axios";
import { SignUpBaseInfoRequestModel } from "../../models/requests/authorization/sign-up/signUpBaseInfoRequest.model";
import { RefreshActionRequestModel } from "../../models/requests/refreshActionRequest.model";
import { SignUpEmailVerificationRequestModel } from "../../models/requests/authorization/sign-up/signUpEmailVerificationRequest.model";
import { SignUpSetPasswordRequestModel } from "../../models/requests/authorization/sign-up/signUpSetPassword.model";
import { getDefaultIdentityApiUrlWithVersion } from "../general-settings.service";


export class SignUpService {

    static build() {
        return new SignUpService();
    }

    checkUsername(username: string) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/sign-up/check-username`, {
            userName: username
        });
    }

    registerBaseInfo(request: SignUpBaseInfoRequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/sign-up/register-base-info`, request);
    }

    resendVerificationEmail(request: RefreshActionRequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/sign-up/resend-verification-email`, request);
    }

    verifyEmail(request: SignUpEmailVerificationRequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/sign-up/verify-email`, request);
    }

    setPassword(request: SignUpSetPasswordRequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/sign-up/set-password`, request);
    }
}