import React from "react";
import { Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import GradientBorder from "../GradientBorder";
import { FormikProps } from "formik";

import '../../styles/forms.scss';
import { ReactElement } from "react";
import { ErrorTooltipIcon } from "./ErrorTooltipIcon";

type ActionButtonElement = ReactElement<any> | null;
export interface BaseFormFieldProps {
    formik: FormikProps<any>,
    fieldNameLabel: string,
    fieldName: string,
    inputProps: any,
    actionButton?: ActionButtonElement;
}

export interface BaseFormFieldInputProps {
    type: string,
    maxLength: number | undefined
    minLength: number | undefined
}

export default function BaseFormField({ formik, fieldNameLabel, fieldName, inputProps, actionButton = null }: BaseFormFieldProps) {
    const showErrorStatus: boolean = !!(formik.errors[fieldName] && formik.touched[fieldName]);
    const showSuccessStatus: boolean = !!(formik.values[fieldName] && !formik.errors[fieldName] && formik.touched[fieldName]);

    const formErrorClass: string = showErrorStatus ? 'form-error' : '';
    const formErrorGriadientClass: string = showErrorStatus ? 'form-input-gradient-border-error' : '';
    const formSuccessGradientClass: string = showSuccessStatus ? 'form-input-gradient-border-success' : '';

    return (
        <FormControl>
            <FormLabel className={`form-label ${formErrorClass}`}>
                {fieldNameLabel}
            </FormLabel>
            <Flex>
                <GradientBorder
                    className={`form-input-gradient-border ${formErrorGriadientClass} ${formSuccessGradientClass}`}>

                    <Input
                        {...inputProps}
                        className="form-input"
                        placeholder={fieldNameLabel}
                        {...formik.getFieldProps(fieldName)} />

                </GradientBorder>

                <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    {showErrorStatus && (
                        <ErrorTooltipIcon text={undefined} formik={formik} fieldName={fieldName} />
                    )}

                    {showSuccessStatus && actionButton}
                </div>
            </Flex>
        </FormControl >
    );
}