import React, { useState } from "react";
import RecoverPasswordEmailSent from "./RecoverPasswordEmailSent";
import RecoverPasswordView from "./RecoverPasswordView";

export interface ModeModel {
    mode: number,
    email: string
}

export default function RecoverPasswordBaseView() {

    const [mode, setMode] = useState({
        mode: 0,
        email: ""
    });

    return (
        <>
            {mode.mode === 0 && <RecoverPasswordView setMode={(value: ModeModel) => {
                setMode(value)
            }} />}
            {mode.mode === 1 && <RecoverPasswordEmailSent email={mode.email} />}
        </>
    );
}