import { format, parseISO } from 'date-fns';

export class DateHelper {

    public static prettyDisplay(date: Date) {
        return format(parseISO(date.toString()), "MMMM dd, yyyy, HH:mm");
    }

    public static parseISO(date: string) {
        return parseISO(date);
    }
}