import { Box, Button, List, ListItem, Text, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verificationCodeValidationSchema } from "../../../modules/models/requests/authorization/sign-up/signUpEmailVerificationRequest.model";
import { FormCommonHandlerService } from "../formCommonHandler";
import BaseFormField from "../../../components/Forms/BaseFormField";

export interface VerificationEmailViewProps {
    email: string;
    checkCode: (code: string) => void;
    refreshCode: () => void;
}

export default function VerificationEmailView(props: VerificationEmailViewProps) {
    const dispatch = useDispatch()
    const toast = useToast()
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [checkedCodes, setCheckedCodes] = useState(new Map());

    const formik = useFormik({
        initialValues: {
            code: ""
        },
        validationSchema: verificationCodeValidationSchema,
        onSubmit: (values) => {
            FormCommonHandlerService.build().setFormTouched(formik)

            if (!formik.isValid) return;

            const oldMap = new Map(checkedCodes);
            oldMap.set(values.code, true);
            setCheckedCodes(oldMap)
            props.checkCode(values.code);
        },
    });

    useEffect(() => {
        FormCommonHandlerService.build().handleCodeChange(formik, checkedCodes);
    }, [formik.values.code, formik, checkedCodes]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                width={"100%"}>
                <Text className="form-container-heading">{t('email_verification', 'Email Verification')}</Text>
                <Text textAlign={"center"} mb={4}>
                    <Trans
                        i18nKey="enter_verification_code"
                        defaultValue={"Please enter the <1>6-digit verification code</1> that was sent to your email address. The code is <3>valid for 15 minutes.</3>"}
                        components={{
                            1: <strong />,
                            3: <strong />
                        }} />
                </Text>
                <Text textAlign={"center"} mb={4}>
                    <Trans
                        i18nKey="verification_email_sent"
                        defaultValue="We sent an email with a verification code to <1>{{email}}</1>"
                        components={{
                            1: <strong />
                        }}
                        values={{ email: props.email }} />
                </Text>

                <BaseFormField
                    actionButton={undefined}
                    inputProps={{
                        type: "text",
                        textAlign: "center",
                        fontSize: "16px!important",
                        fontWeight: "bold"
                    }}

                    formik={formik}
                    fieldName="code"
                    fieldNameLabel={t('verification_code', "Verification Code")}
                />

                <Button
                    variant="solid"
                    colorScheme="orange"
                    className="form-button"
                    py={6}
                    type="submit"
                >
                    {t('verify', "Verify")}
                </Button>

                <Button
                    variant="solid"
                    className="form-button"
                    py={6}
                    mt={4}
                    onClick={() => {
                        setCheckedCodes(new Map());
                        props.refreshCode();
                    }}
                >
                    {t('resend_code', "Resend Code")}
                </Button>
            </Box>
            <Box mt={8}>
                <Text>{"Didn't get the code?"}</Text>
                <List styleType="disc" ml={8}>
                    <ListItem>
                        <Text>{t('code_arrive_n_minutes', {
                            defaultValue: "Codes can take up to {{n}} minutes to arrive.",
                            n: 5
                        })}</Text>

                    </ListItem>
                    <ListItem>
                        <Text>{t('check_spam', 'Check your spam folder.')}</Text>
                    </ListItem>
                </List>
            </Box>
        </form>
    );
}