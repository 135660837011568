import React from "react";
import { Link } from "react-router-dom";
import { UserEmailCredentialModel } from "../../../../../../modules/models/other/user/user-email-credential.model";
import { Box, Button, Flex, HStack, Icon, ListItem, Spacer, Text, VStack } from "@chakra-ui/react";
import { CheckCircleIcon, EmailIcon, UnlockIcon } from "@chakra-ui/icons";
import { Trans } from "react-i18next";

interface EmailRecordViewProps {
    manageEmailAddress: (credential: UserEmailCredentialModel) => void;
    credential: UserEmailCredentialModel;
}

export function MailingRecordView(props: EmailRecordViewProps) {

    const isAntiPhishingTokenValid = props.credential.antiPhishingToken !== null
        && props.credential.antiPhishingToken !== undefined && props.credential.antiPhishingToken !== "";

    return (
        <ListItem as='article' maxW='100%' rounded='md' px={4} py={4} bg={"linear-gradient(to right, #192799, #712be2)"}>
            <Flex>
                <VStack marginTop={"auto"} marginBottom={"auto"}>
                    <HStack as={Link} >
                        <Icon as={EmailIcon} w={6} h={6} marginTop={"auto"} marginBottom={"auto"} mr={2} />
                        <Box>
                            <Text>
                                {props.credential.email}
                            </Text>
                        </Box>
                    </HStack>
                </VStack>

                <Spacer />

                {
                    isAntiPhishingTokenValid &&
                    <>
                        <Icon as={CheckCircleIcon} color={"green.500"} w={4} h={4} marginTop={"auto"} marginBottom={"auto"} mr={2} />
                        <Text fontSize="14px" marginTop={"auto"} fontWeight={"bold"} marginBottom={"auto"} mr={8}>
                            {props.credential.antiPhishingToken}
                        </Text>
                    </>

                }
                {
                    !isAntiPhishingTokenValid &&
                    <>
                        <Icon as={UnlockIcon} color={"red.500"} w={4} h={4}
                            marginTop={"auto"} marginBottom={"auto"} mr={2} />
                        <Text fontSize="14px" marginTop={"auto"} fontWeight={"bold"} marginBottom={"auto"}
                            maxWidth={"200px"}
                            mr={4}>
                            <Trans
                                i18nKey={"anti_phishing_token_not_set"}
                                defaultValue={"Anti-Phishing token not set"} />
                        </Text>
                    </>
                }

                <Button style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    width: "100px"
                }}
                    mx={0}
                    px={0}
                    variant={"solid"}
                    onClick={() => {
                        props.manageEmailAddress(props.credential);
                    }}>
                    <Trans
                        i18nKey={"manage"}
                        defaultValue={"Manage"}
                    />
                </Button>
            </Flex>
        </ListItem>
    );
}