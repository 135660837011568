import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import VerificationEmailView from "./VerificationEmailView";
import VerificationTOTPView from "./VerificationTOTPView";

export interface VerificationModalViewProps {
    actionType: number;
    email: string;
    checkCode: (code: string) => void;
    refreshCode: () => void;
}

export default function VerificationView(props: VerificationModalViewProps) {
    console.log(props)
    return (
        <Box>
            {
                props.actionType === 2 &&
                <VerificationEmailView
                    checkCode={props.checkCode}
                    email={props.email}
                    refreshCode={props.refreshCode}
                />}

            {props.actionType === 1 && <VerificationTOTPView checkCode={props.checkCode} />}
        </Box>
    );
}