import axios from "axios";
import { SignInRequestModel } from "../../models/requests/authorization/sign-in/signInRequest.model";
import { SignInTwoFactorAuthRequestModel } from "../../models/requests/authorization/sign-in/signInTwoFactorAuthRequest.model";
import { getDefaultIdentityApiUrlWithVersion } from "../general-settings.service";

export class SignInService {
    static build() {
        return new SignInService();
    }

    public signIn(request: SignInRequestModel) {
        console.log(request);
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/sign-in`, request);
    }

    public refreshCode(request: any) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/sign-in/refresh-code`, request);
    }

    public secondStep(request: SignInTwoFactorAuthRequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/sign-in/second-step`, request);
    }
}