import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BaseFormField from "../../../components/Forms/BaseFormField";
import VerificationView from "./VerificationView";

export interface VerificationModalViewProps {
    isOpen: boolean;
    onClose: () => void;
    actionType: number;
    email: string;
    checkCode: (code: string) => void;
    refreshCode: () => void;
}

export default function VerificationModalView(props: VerificationModalViewProps) {
    const finalRef = React.useRef(null)

    console.log('here')
    console.log(props.actionType)

    return (
        <>
            <Modal finalFocusRef={finalRef} isOpen={props.isOpen} onClose={props.onClose} size="xl">
                <ModalOverlay />
                <ModalContent bg={"linear-gradient(to right, #252799, #712be2)"} color="white" p={4}>
                    {/* <ModalHeader>Verification</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody>

                        <VerificationView
                            actionType={props.actionType}
                            checkCode={props.checkCode}
                            email={props.email}
                            refreshCode={props.refreshCode} />

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}