import { configureStore } from '@reduxjs/toolkit'
import userRegistrationReducer from './userRegistrationSlice';
import userSignInReducer from './userSignInSlice';
import signInSessionReducer from './signInSessionSlice';
import userProfileReducer from './userProfileSlice';
import recoverReducer from './recoverSlice';
// import other reducers...

const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem('myReduxState');
        if (serializedState === null) {
            return undefined; // Use your reducer's default state
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.error("Could not load state from sessionStorage:", err);
        return undefined;
    }
};

const saveState = (state: any) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('myReduxState', serializedState);
    } catch (err) {
        console.error("Could not save state to sessionStorage:", err);
    }
};

const preloadedState = loadState();
export const store = configureStore({
    reducer: {
        userRegistration: userRegistrationReducer,
        userSignIn: userSignInReducer,
        signInSession: signInSessionReducer,
        userProfile: userProfileReducer,
        recover: recoverReducer
    },
    preloadedState
})

store.subscribe(() => {
    saveState(store.getState());
});