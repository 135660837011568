import { SignInRequestModel } from "../requests/authorization/sign-in/signInRequest.model";
import { SignInTwoFactorAuthRequestModel } from "../requests/authorization/sign-in/signInTwoFactorAuthRequest.model";

export class UserSignInStateModel {
    public signInRequest: SignInRequestModel = new SignInRequestModel();
    public signIn2FactorAuthRequest: SignInTwoFactorAuthRequestModel = new SignInTwoFactorAuthRequestModel();

    public signInStep: number = 0;
    public methodType: number = 0;
    public expiresIn: number = 0;
    public anonymizedEmail: string = '';
    public actionUUID: string = '';


    public getState() {
        return {
            signInRequest: {
                ...this.signInRequest
            },
            signIn2FactorAuthRequest: {
                ...this.signIn2FactorAuthRequest
            },
            signInStep: this.signInStep,
            methodType: this.methodType,
            expiresIn: this.expiresIn,
            anonymizedEmail: this.anonymizedEmail,
            actionUUID: this.actionUUID
        }
    }
}