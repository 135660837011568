import React from "react";
import { WarningIcon } from "@chakra-ui/icons";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { FormikProps } from "formik";

export function ErrorTooltipIcon({ formik, fieldName, text }: { formik: FormikProps<any>, fieldName: string, text: string | undefined }) {
    return (
        <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <Tooltip hasArrow label={text ?? formik.errors[fieldName] as string} bg='red.600'>
                <IconButton
                    variant={'ghost'}
                    colorScheme="red"
                    aria-label="error-icon"
                    className="error-icon-button p4"
                    onClick={() => {
                    }}
                    icon={<WarningIcon
                        className="error-icon"
                        w={5} h={5}
                    />}
                >
                </IconButton>
            </Tooltip>
        </div>
    )
}