import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { UserRegistrationStateModel, steps } from '../models/stores/userRegistrationState.model';
import { SignUpBaseInfoRequestModel } from '../models/requests/authorization/sign-up/signUpBaseInfoRequest.model';
import { SignUpBaseInfoResponseModel } from '../models/responses/authorization/sign-up/signUpBaseInfoResponse.model';
import { SignUpEmailVerificationResponseModel } from '../models/responses/authorization/sign-up/signUpEmailVerificationResponse.model';
import { SignUpSetPasswordRequestModel } from '../models/requests/authorization/sign-up/signUpSetPassword.model';
import { SignUpEmailVerificationRequestModel } from '../models/requests/authorization/sign-up/signUpEmailVerificationRequest.model';

export interface SignUpBaseInfoPayload {
    baseInfo: SignUpBaseInfoRequestModel;
    baseInfoResponse: SignUpBaseInfoResponseModel;
}

const userRegistrationSlice = createSlice({
    name: 'userRegistration',
    initialState: new UserRegistrationStateModel().getState(),
    reducers: {
        updateOnRegisterBaseInfo: (state, action: PayloadAction<SignUpBaseInfoPayload>) => {
            console.log(action.payload);
            return {
                ...state,
                basicInfo: action.payload.baseInfo,
                basicInfoResponse: action.payload.baseInfoResponse,
                currentStep: steps[1],
                currentStepIndex: 1,
                emailVerificationRequest: {
                    ...state.emailVerificationRequest,
                    actionId: action.payload.baseInfoResponse.actionId ?? "",
                    registrationId: action.payload.baseInfoResponse.registrationId ?? ""
                },
                setPasswordRequest: {
                    ...state.setPasswordRequest
                }
            }
        },
        updateVerificationEmailAction: (state, action: PayloadAction<string>) => {
            console.log("NEW CODE", action.payload);
            return {
                ...state,
                emailVerificationRequest: {
                    ...state.emailVerificationRequest,
                    actionId: action.payload
                }
            }
        },
        updatePostVerificationEmail: (state, action: PayloadAction<SignUpEmailVerificationResponseModel>) => {
            return {
                ...state,
                setPasswordRequest: {
                    ...state.setPasswordRequest,
                    registrationId: state.basicInfoResponse.registrationId ?? "",
                    setPasswordActionId: action.payload.emailVerificationConfirmation
                },
                currentStep: steps[2],
                currentStepIndex: 2
            }
        },
        onFinishRegistration: (state) => {
            return {
                ...state,
                basicInfo: {
                    ...new SignUpBaseInfoRequestModel()
                },
                basicInfoResponse: {
                    ...new SignUpBaseInfoResponseModel()
                },
                currentStep: steps[0],
                currentStepIndex: 0,
                emailVerificationRequest: {
                    ...new SignUpEmailVerificationRequestModel()
                },
                setPasswordRequest: {
                    ...new SignUpSetPasswordRequestModel()
                },
                registrationFinished: true,
                registrationEmailAddress: state.basicInfo.email
            }
        },
        finishClearUp: (state) => {
            return {
                ...state,
                basicInfo: {
                    ...state.basicInfo
                },
                basicInfoResponse: {
                    ...state.basicInfoResponse
                },
                currentStep: steps[0],
                currentStepIndex: 0,
                emailVerificationRequest: {
                    ...state.emailVerificationRequest
                },
                setPasswordRequest: {
                    ...state.setPasswordRequest
                },
                registrationFinished: false,
                registrationEmailAddress: ''
            }
        },
        previous: (state) => {
            return {
                ...state,
                basicInfo: {
                    ...state.basicInfo
                },
                basicInfoResponse: {
                    ...state.basicInfoResponse
                },
                currentStep: steps[state.currentStepIndex],
                currentStepIndex: state.currentStepIndex - 1,
                emailVerificationRequest: {
                    ...state.emailVerificationRequest
                },
                setPasswordRequest: {
                    ...state.setPasswordRequest
                }
            }
        }
    }
})

export const {
    updateOnRegisterBaseInfo,
    updateVerificationEmailAction,
    updatePostVerificationEmail,
    onFinishRegistration,
    finishClearUp,
    previous
} = userRegistrationSlice.actions;
export default userRegistrationSlice.reducer;
