import axios from "axios";
import { getDefaultIdentityApiUrlWithVersion } from "../general-settings.service";
import { SetPasswordRequestModel } from "../recover/recover.service";
import { VerifyActionRequestModel } from "../../models/requests/authorization/verifyActionRequest.model";
export class ChangePasswordService {
    static build() {
        return new ChangePasswordService();
    }

    setPassword(request: SetPasswordRequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/user/security/set-password`, request);
    }

    requestVerify() {
        return axios.get(`${getDefaultIdentityApiUrlWithVersion()}/user/security/set-password/request-verify`)
    }

    refreshVerify(actionUUID: string) {
        return axios.get(`${getDefaultIdentityApiUrlWithVersion()}/user/security/set-password/refresh-verify?actionId=` + actionUUID)
    }

    verify(request: VerifyActionRequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/user/security/set-password/verify`, request);
    }
}