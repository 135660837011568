import React from "react";
import { useEffect } from "react";
import {
    Box,
    Container,
} from "@chakra-ui/react";

import '../../../styles/forms.scss';
import SignUpProgress from "./SignUpProgress";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import BaseUserInfoForm from "./BaseUserInfoForm";
import { steps } from "../../../modules/models/stores/userRegistrationState.model";
import { useSelector, useDispatch } from 'react-redux'
import { selectRegistrationProgress } from "../../../modules/models/selectors/userRegistrationSelectors";
import VerifyEmailAddressForm from "./VerifyEmailAddressForm";
import PasswordForm from "./PasswordForm";
import AfterSignUpView from "./AfterSignUpView";
import { finishClearUp, previous } from "../../../modules/stores/userRegistrationSlice";
import { useTranslation } from "react-i18next";

export default function SignUpView() {
    const registrationState = useSelector(selectRegistrationProgress)
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(finishClearUp())
        };
    }, []);

    return (
        <Container
            className="form-flex-containter"
            centerContent
            py="8"
            mt={8}
            mb={16}
        >
            <Box py="4" color="white" px={8}>
                <GoBackButton
                    click={() => {
                        dispatch(previous())
                    }}
                    currentIndex={registrationState.currentStepIndex}
                    text="X-Tour"
                    showBackButton={true}
                />

                {!registrationState.registrationFinished && <SignUpProgress index={registrationState.currentStepIndex} steps={steps} />}

                {registrationState.currentStepIndex === 0 && !registrationState.registrationFinished && <BaseUserInfoForm />}
                {registrationState.currentStepIndex === 1 && <VerifyEmailAddressForm />}
                {registrationState.currentStepIndex === 2 && <PasswordForm />}
                {registrationState.registrationFinished && <AfterSignUpView />}

            </Box>
        </Container >
    );
}
