import * as Yup from 'yup';

export class NewEmailCredentialModel {
    public email: string = '';
    public antiPhishingToken: string = '';

    public static validationSchema = () => Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Required'),
        antiPhishingToken: Yup.string().required('Required')
    })
}