import { createSlice } from "@reduxjs/toolkit";
import getCookie, { deleteCookie } from "../services/cookie.service";

const signInSessionSlice = createSlice({
    name: 'signInSession',
    initialState: {
        loggedIn: !!getCookie("IsLoggedIn"),
        action_uuid: ""
    },
    reducers: {
        setActionUUID(state, action) {
            return {
                ...state,
                action_uuid: action.payload
            }
        },
        login(state) {
            return {
                ...state,
                loggedIn: true,
            }
        },
        logout(state) {
            deleteCookie("IsLoggedIn")
            deleteCookie("AccessToken")
            return {
                ...state,
                loggedIn: false,
            }
        }
    }
});

export const {
    login,
    logout,
    setActionUUID
} = signInSessionSlice.actions;

export default signInSessionSlice.reducer;
