import * as Yup from 'yup';

export class SignInRequestModel {
    public name: string = '';
    public password: string = '';

    public static validationSchema = () => Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .matches(/^\S{8,}$/, "Invalid password. Please try again.")
        ,
        name: Yup.string()
            .required('Username or Email is required')
            .matches(
                /^([a-zA-Z0-9_.-]+|([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+))$/,
                'Invalid format'
            )
    })
}