import { createSelector } from "@reduxjs/toolkit";
import { UserSignInStateModel } from "../stores/userSignInState.model";

const selectUserSignIn = (state: any) => state.userSignIn;

export const selectUserSignInSelector = createSelector(
    [selectUserSignIn],
    (user: UserSignInStateModel) => user.signInRequest
);

export const selectUser2FactorSignInSelector = createSelector(
    [selectUserSignIn],
    (user: UserSignInStateModel) => user.signIn2FactorAuthRequest
);

export const selectUserSignInStepSelector = createSelector(
    [selectUserSignIn],
    (user: UserSignInStateModel) => {
        return {
            step: user.signInStep,
            methodType: user.methodType
        }
    }
);

export const signIn2FactorAuthRequestSelector = createSelector(
    [selectUserSignIn],
    (user: UserSignInStateModel) => {
        return {
            request: user.signIn2FactorAuthRequest,
            anonymizedEmail: user.anonymizedEmail
        }
    }
);