import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React from "react";
import ChangePasswordView from "./ChangePasswordView";

export interface ChangePasswordModalViewProps {
    isOpen: boolean;
    onClose: () => void;
    setPassword: (password: string) => void
}

export default function ChangePasswordModalView(props: ChangePasswordModalViewProps) {
    const finalRef = React.useRef(null)

    return (
        <>
        <Modal finalFocusRef={finalRef} isOpen={props.isOpen} onClose={props.onClose} size="xl">
            <ModalOverlay />
            <ModalContent bg={"linear-gradient(to right, #252799, #712be2)"} color="white" p={4}>
                {/* <ModalHeader>Change Password</ModalHeader> */}
                <ModalCloseButton />
                <ModalBody>

                    <ChangePasswordView
                        setPassword={(password: string) => {
                            props.setPassword(password);
                        }}
                    />

                </ModalBody>
            </ModalContent>
        </Modal>
    </>
    );
}