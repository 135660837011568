import * as Yup from 'yup';

export function passwordValidator() {
    return Yup.string()
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
            "Password must meet the following criteria:\n" +
            "- Minimum 8 characters.\n" +
            "- At least one uppercase letter (A-Z).\n" +
            "- At least one lowercase letter (a-z).\n" +
            "- At least one number (0-9).\n" +
            "- At least one special character (e.g., @, $, !, %, *, ?, &)."
        );
}

export class PasswordValidator {
    public static validationSchema = () => Yup.object().shape({
        password: passwordValidator()
    })
}

export class SignUpSetPasswordRequestModel {
    public registrationId: string = '';
    public setPasswordActionId: string = '';
    public password: string = '';
    public confirmPassword: string = '';

    public static validationSchema = () => Yup.object().shape({
        password: passwordValidator(),
        confirmPassword: Yup.string()
            .required('Please confirm your password')
            .oneOf([Yup.ref('password')], 'Passwords must match')
    })
}