import React, { useEffect, useState } from "react";
import { UserEmailCredentialModel, UserEmailCredentialResponse } from "../../../../../modules/models/other/user/user-email-credential.model";
import { Box, Button, Card, Flex, FormControl, FormLabel, HStack, Icon, IconButton, Input, LinkBox, Spacer, Text, VStack, useDisclosure, useToast } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import BaseFormField from "../../../../../components/Forms/BaseFormField";
import GradientBorder from "../../../../../components/GradientBorder";
import { FaArrowCircleRight, FaBirthdayCake, FaCheckCircle, FaInfo, FaInfoCircle, FaPencilAlt, FaTrash } from "react-icons/fa";
import ConditionalIcon from "../../../../../components/ConditionalIcon";
import { Link, useResolvedPath } from "react-router-dom";
import { EmailIcon, LockIcon, UnlockIcon } from "@chakra-ui/icons";
import { DateHelper } from "../../../../../modules/helpers/dateHelper";
import SetAntiPhishingCodeModal from "./AntiPhishingModelView";
import { SecurityServiceHandler } from "../../../../../modules/services/account/security-handler.service";
import { useDispatch } from "react-redux";
import { VerificationActionResponseModel } from "../../../../../modules/models/responses/authorization/verifyActionResponse.model";
import VerificationModalView from "../../../Verification/VerificationModalView";
import { GlobalResponseModel } from "../../../../../modules/models/responses/globalResponse.model";
import GoBackButton from "../../../../../components/Buttons/GoBackButton";

export interface EmailManageCredentialViewProps {
    credential: UserEmailCredentialModel;
    goBack: () => void;
}

export default function EmailManageCredentialView(props: EmailManageCredentialViewProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useToast();
    const { isOpen: isOpenTokenModal, onOpen: onOpenTokenModal, onClose: onCloseTokenModal } = useDisclosure()
    const { isOpen: isOpenVerificationModal, onOpen: onOpenVerificationModal, onClose: onCloseVerificationModal } = useDisclosure()
    const [verificationResponse, setVerificationResponse] = useState<VerificationActionResponseModel>();

    const [emailCredential, setEmailCredential] = useState<UserEmailCredentialModel>();

    useEffect(() => {
        SecurityServiceHandler.build().getEmail(props.credential.credentialUUID, dispatch, toast, (data: UserEmailCredentialResponse) => {
            console.log('data', data);
            if (data.statusCode !== 0) return;
            setEmailCredential(data.emailCredential);
        });
    }, []);

    if (!emailCredential) return (<></>);

    return (
        <Card w='2xl' bg={"linear-gradient(to right, #252799, #712be2)"} color="white" p={4} >
            <Box
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                width={"100%"}
                p={4} px={8}>

                <GoBackButton
                    click={() => {
                        props.goBack()
                    }}
                    currentIndex={2}
                    text="X-Tour"
                    showBackButton={true}
                />


                <Text className="form-container-heading" textAlign="center">
                    {t('manage_email_address', 'Manage Your Email Address')}
                </Text>

                <Text textAlign={"center"}>
                    {t('manage_email_address_com', 'Here you can manage your email address. You can set an anti-phishing token to protect your account from phishing attacks. You can also decide if this email address should participate in the authentication flow.')}
                </Text>

                <Flex>
                    <FormControl mt={8}>
                        <FormLabel className={`form-label`}>
                            {t('email', 'Email Address')}
                        </FormLabel>
                        <Flex>

                            <GradientBorder
                                className={`form-input-gradient-border`}>

                                <Input
                                    readOnly={true}
                                    className="form-input"
                                    placeholder={emailCredential.email} />

                            </GradientBorder>

                            <IconButton
                                aria-label="Remove"
                                icon={<FaTrash />}
                                colorScheme="red"
                                size="lg"
                                variant={"ghost"}
                                mx={1}
                            />
                        </Flex>
                    </FormControl >
                </Flex>
                <LinkBox as='article' maxW='2xl' rounded='md' px={2} mt={4} >
                    <Flex>
                        <VStack marginTop={"auto"} marginBottom={"auto"}>
                            <HStack as={Link}>
                                <Icon as={FaBirthdayCake} mr={4} w={6} h={6} />
                                <Box>
                                    <Text fontSize="16px" fontWeight={"bold"}>
                                        {t('created_at', "Created at")}
                                    </Text>
                                    <Text fontSize="12px">
                                        {t('creation_date_of', "The creation date of the email address")}
                                    </Text>
                                </Box>
                            </HStack>
                        </VStack>
                        <Spacer />
                        <Text fontSize="14px" fontWeight={"bold"} width={"160px"} textAlign={"center"} marginTop={"auto"} marginBottom={"auto"}>
                            {DateHelper.prettyDisplay(emailCredential.createDate)}
                        </Text>
                    </Flex>
                </LinkBox>
                <LinkBox as='article' maxW='2xl' rounded='md' px={2} mt={8} >
                    <Flex>
                        <VStack marginTop={"auto"} marginBottom={"auto"}>
                            <HStack as={Link}>
                                <Icon as={FaPencilAlt} mr={4} w={6} h={6} />
                                <Box>
                                    <Text fontSize="16px" fontWeight={"bold"}>
                                        {t('modified_at', "Modified at")}
                                    </Text>
                                    <Text fontSize="12px">
                                        {t('date_record_modified', "The date the record was last modified")}
                                    </Text>
                                </Box>
                            </HStack>
                        </VStack>
                        <Spacer />
                        <Text fontSize="14px" fontWeight={"bold"} width={"160px"} textAlign={"center"} marginTop={"auto"} marginBottom={"auto"}>
                            {DateHelper.prettyDisplay(emailCredential.lastModificationDate)}
                        </Text>
                    </Flex>
                </LinkBox>
                <LinkBox as='article' maxW='2xl' rounded='md' px={2} mt={8} >
                    <Flex>
                        <VStack marginTop={"auto"} marginBottom={"auto"}>
                            <HStack as={Link}>

                                {emailCredential.verified &&
                                    <>
                                        <Icon as={FaCheckCircle} color={"green.500"} mr={4} w={6} h={6} />
                                        <Box>
                                            <Text fontSize="16px" fontWeight={"bold"}>
                                                {t('email_verified', "Email Verified")}
                                            </Text>
                                            <Text fontSize="12px">
                                                {t('email_verified_com', "Email Address has been verified")}
                                            </Text>
                                        </Box>
                                    </>
                                }

                                {!emailCredential.verified &&
                                    <>
                                        <Icon as={FaInfoCircle} color={"red.500"} mr={4} w={6} h={6} />
                                        <Box>
                                            <Text fontSize="16px" fontWeight={"bold"}>
                                                {t('email_not_verified', "Email Not Verified")}
                                            </Text>
                                            <Text fontSize="12px">
                                                {t('email_not_verified_com', "Email Address has not been verified")}
                                            </Text>
                                        </Box>
                                    </>
                                }
                            </HStack>
                        </VStack>
                        <Spacer />
                        {
                            !emailCredential.verified &&
                            <Button style={{
                                marginTop: "auto",
                                marginBottom: "auto"
                            }}
                                mx={0}
                                px={8}
                                width={"160px"}
                                variant={"solid"}>
                                {t('verify', "Verify")}
                            </Button>
                        }
                        {
                            emailCredential.verified &&
                            <Text fontSize="14px" fontWeight={"bold"} width={"160px"} textAlign={"center"} marginTop={"auto"} marginBottom={"auto"}>
                                {DateHelper.prettyDisplay(emailCredential.confirmationDate)}
                            </Text>
                        }
                    </Flex>
                </LinkBox>
                <LinkBox as='article' maxW='2xl' rounded='md' px={2} mt={8} >
                    <Flex>
                        <VStack marginTop={"auto"} marginBottom={"auto"}>
                            <HStack as={Link}>
                                {
                                    !emailCredential.antiPhishingToken && <>
                                        <Icon as={UnlockIcon} color={"red.500"} mr={4} w={6} h={6} />
                                        <Box>
                                            <Text fontSize="16px" fontWeight={"bold"}>
                                                {t('anti_phishing_code', "Anti-Phishing Code")}
                                            </Text>
                                            <Text fontSize="12px">
                                                {t('anti_phishing_token_not_set', "Anti-Phishing Token has not been set")}
                                            </Text>
                                        </Box>
                                    </>
                                }
                                {
                                    !!emailCredential.antiPhishingToken && <>
                                        <Icon as={LockIcon} color={"green.500"} mr={4} w={6} h={6} />
                                        <Box>
                                            <Text fontSize="16px" fontWeight={"bold"}>
                                                {t('anti_phishing_code', "Anti-Phishing Code")}
                                            </Text>
                                            <Text fontSize="12px">
                                               {emailCredential.antiPhishingToken}
                                            </Text>
                                        </Box>
                                    </>
                                }
                            </HStack>
                        </VStack>
                        <Spacer />
                        {
                            !emailCredential.antiPhishingToken && <Button style={{
                                marginTop: "auto",
                                marginBottom: "auto"
                            }}
                                onClick={
                                    onOpenTokenModal
                                }
                                width={"160px"}
                                mx={0}
                                px={8}
                                variant={"solid"}>
                                {t('set_token', "Set Token")}
                            </Button>
                        }
                        {
                            !!emailCredential.antiPhishingToken && <Button style={{
                                marginTop: "auto",
                                marginBottom: "auto"
                            }}
                            onClick={
                                onOpenTokenModal
                            }
                                width={"160px"}
                                mx={0}
                                px={8}
                                variant={"solid"}>
                                {t('update_token', "Update Token")}
                            </Button>
                        }
                    </Flex>
                </LinkBox>
            </Box>
            <SetAntiPhishingCodeModal isOpen={isOpenTokenModal} onClose={onCloseTokenModal} email={emailCredential.email}
                onSet={(password) => {
                    onCloseTokenModal();
                    SecurityServiceHandler.build().setAntiPhishingToken({
                        antiPhishingToken: password,
                        emailCredential: emailCredential.credentialUUID
                    }, dispatch, toast, (resp: VerificationActionResponseModel) => {
                        if (resp.statusCode !== 0) return;

                        setVerificationResponse(resp);
                        onOpenVerificationModal();
                    });
                }}
            />

            {
                !!verificationResponse &&
                <VerificationModalView
                    isOpen={isOpenVerificationModal}
                    onClose={onCloseVerificationModal}
                    actionType={verificationResponse.actionType}
                    email={emailCredential.email}    
                    checkCode={(code: string) => {
                        console.log('verification response', verificationResponse)
                        SecurityServiceHandler.build().verifyAntiPhishingToken({
                            actionId: verificationResponse.actionUUID,
                            code: code
                        }, dispatch, toast, (response: GlobalResponseModel) => {
                            console.log(response);

                            if (response.statusCode === 0) {
                                SecurityServiceHandler.build().getEmail(props.credential.credentialUUID, dispatch, toast, (data: UserEmailCredentialResponse) => {
                                    console.log('data', data);
                                    if (data.statusCode !== 0) return;
                                    setEmailCredential(data.emailCredential);
                                });
                                onCloseVerificationModal();
                            }
                        });
                    }}
                    refreshCode={() => {
                        SecurityServiceHandler.build().refreshVerificationAction(verificationResponse.actionUUID, dispatch, toast,
                            (response: VerificationActionResponseModel) => {
                                if (response.statusCode !== 0) return;

                                console.log('response ..', response)
                                setVerificationResponse({
                                    ...response
                                })
                            });
                    }}
                />
            }

 
        </Card>
    );
}