import axios from "axios";
import { getDefaultIdentityApiUrlWithVersion } from "../general-settings.service";
import * as Yup from 'yup';

export class RecoverAccountStep1RequestModel {
    public email: string = "";

    public static validationSchema = () => Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Required')
    })
}

export interface RecoverVerifyCodeRequestModel {
    actionId: string;
    code: string;
}

export interface SetPasswordRequestModel {
    setPasswordActionId: string;
    password: string;
}

export class RecoverAccountService {
    static build() {
        return new RecoverAccountService();
    }

    public verifyCode(request: RecoverVerifyCodeRequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/recover/verify`, request);
    }

    public setPassword(request: SetPasswordRequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/recover/set-password`, request);
    }

    public recoverStepOne(request: RecoverAccountStep1RequestModel) {
        return axios.post(`${getDefaultIdentityApiUrlWithVersion()}/recover/step1`, request);
    }

    public refreshVerifyCode(actionUUID: string) {
        return axios.get(`${getDefaultIdentityApiUrlWithVersion()}/recover/verify/refresh?actionUUID=` + actionUUID);
    }
}