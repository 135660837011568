import * as Yup from 'yup';
export interface UserRegisterTotpResponse {
    alreadyRegistered: boolean;
    encodedTotpUrl: string;
    mnemonic: string[];

    stateRead: boolean;
}

export const codeYupGenerator = () => {
    return Yup.string()
        .min(6, 'Verification Code should be exactly 6 characters long')
        .max(6, 'Verification Code should be exactly 6 characters long')
        .matches(
            /^[0-9]{6}$/,
            'Only numbers are allowed'
        )
        .required('Verification code is required');
}

export const totpVerificationValidationSchema = () => Yup.object().shape({
    firstCode: codeYupGenerator(),
    secondCode: codeYupGenerator()
})