import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const isProduction = process.env.NODE_ENV === 'production';

import enTranslations from './assets/locales/en.json';
import plTranslations from './assets/locales/pl.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: !isProduction,
        fallbackLng: 'pl',
        lng: 'pl',
        saveMissing: !isProduction, // you should not use saveMissing in production
        // keySeparator: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: enTranslations
            },
            pl: {
                translation: plTranslations
            },
        }
    });

export default i18n;