import { Box, Button, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import BaseFormField from "../../../components/Forms/BaseFormField";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FormCommonHandlerService } from "../formCommonHandler";
import { verificationCodeValidationSchema } from "../../../modules/models/requests/authorization/sign-up/signUpEmailVerificationRequest.model";

export interface VerificationTOTPViewProps {
    checkCode: (code: string) => void;
}

export default function VerificationTOTPView(props: VerificationTOTPViewProps) {
    const dispatch = useDispatch()
    const toast = useToast()
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [checkedCodes, setCheckedCodes] = useState(new Map());

    const formik = useFormik({
        initialValues: {
            code: ""
         },
        validationSchema: verificationCodeValidationSchema,
        onSubmit: (values) => {
            FormCommonHandlerService.build().setFormTouched(formik)

            if (!formik.isValid) return;

            const oldMap = new Map(checkedCodes);
            oldMap.set(values.code, true);
            setCheckedCodes(oldMap)
            props.checkCode(values.code);
        },
    });

    useEffect(() => {
        FormCommonHandlerService.build().handleCodeChange(formik, checkedCodes);
    }, [formik.values.code, formik, checkedCodes]);


    return (
        <form onSubmit={formik.handleSubmit}>
            <Box display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                pb={4}
                width={"100%"}>
                <Text className="form-container-heading">{t('totp_verification', "TOTP Verification")}</Text>
                <Text textAlign={"center"} mb={4}>
                    <Trans
                        i18nKey="enter_code_authenticator"
                        defaultValue={"Please enter the <1>6-digit verification code</1> that is generated in your <1>Authenticator App.</1>"}
                        components={[
                            <strong></strong>,
                            <strong></strong>,
                        ]} />
                </Text>

                <BaseFormField
                    actionButton={undefined}
                    inputProps={{
                        type: "text",
                        textAlign: "center",
                        fontSize: "16px!important",
                        fontWeight: "bold"
                    }}

                    formik={formik}
                    fieldName="code"
                    fieldNameLabel={t('verification_code', "Verification Code")}
                />

                <Button
                    variant="solid"
                    colorScheme="orange"
                    className="form-button"
                    py={6}
                    type="submit"
                >
                    {t('verify', "Verify")}
                </Button>
            </Box>
        </form>
    );
}
