import { Dispatch } from "react";
import { UserSecurityService } from "./security.service";
import { HttpValidatorService } from "../http-validator.service";
import { UserSecurityConfigResponse } from "../../models/responses/user/user-security-config-response.model";
import { UserRegisterTotpResponse } from "../../models/responses/user/user-register-totp-response.model";
import { UserVerifyTotpRequestModel } from "../../models/requests/authorization/user/user-verify-totp-request.model";
import { BaseUserResponseModel } from "../../models/responses/baseUserResponse.model";
import { UserEmailCredentialResponse, UserEmailCredentialResponseModel } from "../../models/other/user/user-email-credential.model";
import { UserSetAntiPhishingTokenRequestModel } from "../../models/requests/authorization/user/user-set-ani-phishing-token-request.model";
import { VerificationActionResponseModel } from "../../models/responses/authorization/verifyActionResponse.model";
import { VerifyActionRequestModel } from "../../models/requests/authorization/verifyActionRequest.model";
import { GlobalResponseModel } from "../../models/responses/globalResponse.model";

export class SecurityServiceHandler {

    static build() {
        return new SecurityServiceHandler();
    }

    info(dispatch: Dispatch<any>, toast: any, handle: (data: UserSecurityConfigResponse) => void = () => { }) {
        UserSecurityService.build().userSecurityInfo().then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: UserSecurityConfigResponse) => {
                handle(data);
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }, (err: any) => {
            console.log("ERRPR", err)
        });
    }

    registerTotp(dispatch: Dispatch<any>, toast: any, handle: (data: UserRegisterTotpResponse) => void = () => { }) {
        UserSecurityService.build().registerTotp().then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: UserRegisterTotpResponse) => {
                handle(data);
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    verifyTotp(request: UserVerifyTotpRequestModel, dispatch: Dispatch<any>, toast: any, handle: (data: any) => void = () => { }) {
        UserSecurityService.build().verifyTotp(request).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: BaseUserResponseModel) => {
                handle(data);
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    getEmail(emailUUID: string, dispatch: Dispatch<any>, toast: any, handle: (data: UserEmailCredentialResponse) => void = () => { }) {
        UserSecurityService.build().getEmail(emailUUID).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: UserEmailCredentialResponse) => {
                handle(data);
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    getEmails(dispatch: Dispatch<any>, toast: any, handle: (data: UserEmailCredentialResponseModel) => void = () => { }) {
        UserSecurityService.build().getEmails().then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: UserEmailCredentialResponseModel) => {
                handle(data);
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    setAntiPhishingToken(request: UserSetAntiPhishingTokenRequestModel, dispatch: Dispatch<any>, toast: any,
        handle: (data: VerificationActionResponseModel) => void = () => { }) {
        UserSecurityService.build().setAntiPhishingToken(request).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: VerificationActionResponseModel) => {
                handle(data);
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    verifyAntiPhishingToken(request: VerifyActionRequestModel, dispatch: Dispatch<any>, toast: any,
        handle: (data: GlobalResponseModel) => void = () => { }) {
        UserSecurityService.build().verifyAntiPhishingToken(request).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: GlobalResponseModel) => {
                handle(data);
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    refreshVerificationAction(actionUUID: string, dispatch:  Dispatch<any>, toast: any, handle: (data: VerificationActionResponseModel) => void = () => {}) {
        UserSecurityService.build().refreshVerificationAction(actionUUID).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: VerificationActionResponseModel) => {
                handle(data);
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }).catch((error) => {
            console.log(error);
        });
    }
}