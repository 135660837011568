import React from "react";
import { Flex, Spacer } from "@chakra-ui/react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { Outlet } from 'react-router-dom';

export default function Profile() {
    return (
        <Flex>
            <Sidebar />
            <Flex py={8} alignContent="center" width={"100%"}>
                <Spacer />
                {/* <TOTPSetup /> */}
                <Outlet />
                <Spacer />
            </Flex>
        </Flex>

    );
}