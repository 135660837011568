import { Box, Card, Flex, Icon, List, Spacer, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { UserEmailCredentialModel, UserEmailCredentialResponseModel } from "../../../../../../modules/models/other/user/user-email-credential.model";
import { SecurityServiceHandler } from "../../../../../../modules/services/account/security-handler.service";
import { EmailIcon } from "@chakra-ui/icons";
import ConditionalIcon from "../../../../../../components/ConditionalIcon";
import { MailingRecordView } from "./EmailRecordView";
import EmailAddCredentialView from "../EmailAddCredentialView";
import { EmailCredentialViewState } from "../EmailCredentialsView";
import GoBackButton from "../../../../../../components/Buttons/GoBackButton";
import { resolvePath, useNavigate } from "react-router-dom";

interface EmailPreviewCredentialsViewProps {
    setStage: (stage: EmailCredentialViewState) => void;
    manageEmailAddress: (credential: UserEmailCredentialModel) => void;
}

export default function EmailPreviewCredentialsView(props: EmailPreviewCredentialsViewProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useToast();
    const navigate = useNavigate()

    const [credentials, setCredentials] = useState<UserEmailCredentialResponseModel>();
    const parentPath = resolvePath("/dashboard/security")

    console.log('path', parentPath)

    useEffect(() => {
        SecurityServiceHandler.build().getEmails(dispatch, toast, (resp: UserEmailCredentialResponseModel) => {
            console.log(resp);
            if (resp.statusCode !== 0) {
                return;
            }

            setCredentials(resp);
        });
    }, []);

    return (
        <Box
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            color={"white"}
            p={4} px={8}>
                
            <GoBackButton
                    click={() => {
                        navigate(parentPath)
                        
                    }}
                    currentIndex={2}
                    text="X-Tour"
                    showBackButton={true}
                />
            <Flex>
                <Icon as={EmailIcon} w={6} h={6} marginTop={"auto"} marginBottom={"auto"} mr={4} mt={4} />
                <Text className="form-container-heading" style={{
                    marginTop: "8px",
                    marginBottom: "0"
                }}>
                    <Trans
                        i18nKey="email_credentials"
                        defaultValue={"Email credentials"} />
                </Text>
                <Spacer />
            </Flex>
            <Flex mt={0} pt={0}>
                <Text fontSize={"14px"} maxWidth={"50%"} ml={"40px"}>
                    <Trans
                        i18nKey={"email_credentials_com"}
                        defaultValue={"For added account security, manage your email credentials. They are needed to communicate and verify your identity. The account must have at least one email credential."}
                    />
                </Text>

            </Flex>
            <Flex mt={4}>
                <Box display="flex" justifyContent="start" ml={"40px"}>
                    <ConditionalIcon condition={credentials?.antiPhishingTokensSet ?? false} fontSize={"18px"} />
                    <Text fontSize="14px">
                        <Trans
                            i18nKey={"anti_phishing_code"}
                            defaultValue={"Anti-Phishing Code"}
                        />
                    </Text>
                </Box>
            </Flex>

            <Card width={"60%"} bg={"linear-gradient(to right, #252799, #712be2)"} color="white" py={6} px={8} mt={8}>
                <Text style={{
                    marginBottom: "0",
                    fontWeight: "bold",
                    fontSize: "18px"
                }}>
                    <Trans
                        i18nKey="email_credentials"
                        defaultValue={"Email credentials"} />
                </Text>
                <List mt={4}>
                    {
                        credentials?.emailCredentials.map((item) => {
                            return (
                                <div key={item.credentialUUID}>
                                    <MailingRecordView credential={item}
                                        manageEmailAddress={props.manageEmailAddress}
                                    />
                                </div>
                            );
                        })
                    }
                </List>
            </Card>

            <EmailAddCredentialView />
        </Box>
    );
}