import React, { useState } from "react";
import {
    Grid,
    GridItem,
    Button,
    Link,
    Center,
    Flex,
    IconButton
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUserLoggedIn } from "../../modules/models/selectors/signInSessionSelector";
import { logout } from "../../modules/stores/signInSessionSlice";
import { useTranslation } from "react-i18next";

function Navbar() {
    const { t } = useTranslation();
    const [isMobileNavOpen, setMobileNavOpen] = useState(true);
    const toggleMobileNav = () => {
        setMobileNavOpen(!isMobileNavOpen);
    };

    const userLoggedIn = useSelector(selectUserLoggedIn)
    const dispatch = useDispatch()

    return (
        <Grid
            gap={6}
            as="nav"
            paddingLeft={{ base: "1rem", md: "8rem" }}
            paddingRight={{ base: "1rem", md: "8rem" }}
            paddingTop="1.5rem"
            paddingBottom="1.5rem"
            bg="transparent"
            templateColumns={{ base: "1fr", md: "repeat(9, 1fr)" }}
            color="white"
            alignItems="center"
            justifyContent={{ base: "center", md: "flex-end" }}
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        >
            <GridItem
                colSpan={{ base: 12, md: 3 }}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                textAlign={{ base: "center", md: "left" }}
            >
                <Button
                    position={{ base: "relative", md: "static" }}
                    left={{ base: "50%", md: "auto" }}
                    transform={{ base: "translate(-50%, 0)", md: "none" }}
                    as={RouterLink} to="/" variant="link" fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold">
                    X-Tour
                </Button>
                <IconButton
                    display={{ base: "flex", md: "none" }}
                    onClick={toggleMobileNav}
                    variant={"ghost"}
                    colorScheme="white"
                    fontSize={{ base: "xl", md: "2xl" }}
                    aria-label="Open navigation menu"
                    icon={isMobileNavOpen ? <CloseIcon /> : <HamburgerIcon />}
                />
            </GridItem>
            {isMobileNavOpen &&
                <GridItem colSpan={{ base: 12, md: 3 }} w="100%" fontWeight="bold">
                    <Center flexDirection={{ base: "column", md: "row" }}>
                        <Link px={{ base: 2, md: 4 }} mb={{ base: 2, md: 0 }}>
                            {t('products', "Products")}
                        </Link>
                        <Link px={{ base: 2, md: 4 }} mb={{ base: 2, md: 0 }}>
                            {t('community', "Community")}
                        </Link>
                        <Link px={{ base: 2, md: 4 }} mb={{ base: 2, md: 0 }}>
                            {t('about', "About")}
                        </Link>
                        <Link px={{ base: 2, md: 4 }} mb={{ base: 2, md: 0 }}>
                            {t('blog', "Blog")}
                        </Link>
                    </Center>
                </GridItem>
            }
            {isMobileNavOpen && <GridItem colSpan={{ base: 12, md: 3 }} w="100%">
                <Flex flexDirection={{ base: "column", md: "row" }} justifyContent={{ base: "center", md: "flex-end" }}>
                    {
                        userLoggedIn &&
                        <Button
                            mx={{ base: 2, md: 4 }}
                            my={{ base: 2, md: 0 }}
                            variant="ghost"
                            textColor="white"
                            w={{ base: "100%", md: "auto" }}
                            onClick={() => {
                                dispatch(logout())
                            }}>
                            {t('logout', "Logout")}
                        </Button>
                    }
                    {
                        !userLoggedIn &&
                        <Flex>
                            <Button mx={{ base: 2, md: 4 }} my={{ base: 2, md: 0 }} variant="ghost" textColor="white" as={RouterLink} to="/sign-in" w={{ base: "100%", md: "auto" }}>
                                {t('sign_in', "Sign In")}
                            </Button>
                            <Button
                                mx={{ base: 2, md: 4 }}
                                my={{ base: 2, md: 0 }}
                                variant="solid"
                                textColor="white"
                                as={RouterLink}
                                to="/sign-up"
                                background={"linear-gradient(to right, #3388FF, #8A2BE2)"}
                                w={{ base: "100%", md: "auto" }}>
                                {t('join_us', "Join Us")}
                            </Button>
                        </Flex>
                    }
                </Flex>
            </GridItem>}
        </Grid >
    );
}

export default Navbar;
