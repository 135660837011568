import { createSlice } from "@reduxjs/toolkit";

export interface RecoverStateModel {
    actionUUID: string;
    actionType: number;
    actionDescription: string;
    statusCode: number;
    errMessage: string;
    errDescription: string;
    done: boolean;
}

const recoverSlice = createSlice({
    name: 'recoverSlice',
    initialState: {
        actionUUID: "",
        actionType: -1,
        actionDescription: "",
        statusCode: "",
        errMessage: "",
        errDescription: "",
        done: false
    },
    reducers: {
        setRecoverState(state, action) {
            return {
                done: false,
                ...action.payload
            }
        },
        recoverClear(state) {
            return {
                actionUUID: "",
                actionType: -1,
                actionDescription: "",
                statusCode: "",
                errMessage: "",
                errDescription: "",
                done: false
            }
        },
        setDone(state) {
            return {
                actionUUID: "",
                actionType: -1,
                actionDescription: "",
                statusCode: "",
                errMessage: "",
                errDescription: "",
                done: true
            }
        }
    }
});

export const {
    setRecoverState,
    recoverClear,
    setDone
} = recoverSlice.actions;

export default recoverSlice.reducer;
