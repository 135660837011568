import axios from "axios";
import { getDefaultIdentityApiUrlWithVersion } from "../general-settings.service";

export class ProfileService {
    static build() {
        return new ProfileService();
    }

    dashboard() {
        return axios.get(`${getDefaultIdentityApiUrlWithVersion()}/user/profile/dashboard`);
    }
}