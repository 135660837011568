import { Dispatch } from "react";
import { SignInRequestModel } from "../../models/requests/authorization/sign-in/signInRequest.model";
import { SignInService } from "./sign-in.service";
import { HttpValidatorService } from "../http-validator.service";
import { SignInResponseModel } from "../../models/responses/authorization/sign-in/signInResponse.model";
import { onRefreshCode, onSimpleSignIn } from "../../stores/userSignInSlice";
import { SignInRefreshCodeModel } from "../../models/requests/authorization/sign-in/signInRefreshCode.model";
import { RefreshActionResponseModel } from "../../models/responses/refreshActionResponse.model";
import { BaseUserResponseModel } from "../../models/responses/baseUserResponse.model";

export class SignInHandlerService {
    static build() {
        return new SignInHandlerService();
    }

    signIn(values: any, dispatch: Dispatch<any>, toast: any,
        onSingIn: (data: SignInResponseModel) => void = (data: SignInResponseModel) => { }) {
        const request: SignInRequestModel = {
            ...values
        };

        SignInService.build().signIn(request).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: SignInResponseModel) => {
                console.log(data);
                if (!data.twoFactorAuth) {
                    onSingIn(data);
                    return;
                }
                dispatch(onSimpleSignIn(data));
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }, (err: any) => {
            console.log("ERRPR", err)
        });
    }

    refreshCode(request: SignInRefreshCodeModel, dispatch: Dispatch<any>, toast: any) {
        SignInService.build().refreshCode(request).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: RefreshActionResponseModel) => {
                console.log(data);
                dispatch(onRefreshCode(data));

            }, () => {
                console.log("ERROR")
            }, dispatch)
        }, (err: any) => {
            console.log("ERRPR", err)
        });
    }

    secondStep(request: any, dispatch: Dispatch<any>, toast: any,
        onSingIn: (data: BaseUserResponseModel) => void = (data: BaseUserResponseModel) => { }) {

        SignInService.build().secondStep(request).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: BaseUserResponseModel) => {
                console.log(data);
                onSingIn(data)
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }, (err: any) => {
            console.log("ERRPR", err)
        });
    }

}