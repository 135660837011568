import React from "react";
import { Flex, FormControl, FormLabel, Select } from "@chakra-ui/react"
import { isValidDate } from "../../modules/models/requests/authorization/sign-up/signUpBaseInfoRequest.model"
import GradientBorder from "../GradientBorder"
import { CalendarHelper } from "../../modules/helpers/calendarHelper"
import { ErrorTooltipIcon } from "./ErrorTooltipIcon"

import '../../styles/forms.scss'
import { FormikProps } from "formik"
import { useTranslation } from "react-i18next";

interface DateFieldProps {
    formik: FormikProps<any>
}

export default function DateField({ formik, }: DateFieldProps) {
    const { t } = useTranslation();
    const touched = (formik.touched.day || formik.touched.month || formik.touched.year)

    const isDateSuccess = isValidDate(formik.values.day, formik.values.month, formik.values.year) && touched
    const isDateError = (formik.errors.day || formik.errors.month || formik.errors.year || !isDateSuccess)
        && touched

    const months = CalendarHelper.build().getAvailableMonths();
    const years = CalendarHelper.build().getAvailableYears();
    const days = CalendarHelper.build().getAvailableDays();

    return (
        <FormControl>
            <FormLabel className="form-label">{t('date_of_birth', "Date of Birth")}</FormLabel>
            <Flex>
                <GradientBorder className={`form-input-gradient-border 
            ${isDateError ? 'form-input-gradient-border-error' : ''}
            ${isDateSuccess ? 'form-input-gradient-border-success' : ''}`}>
                    <Flex className="date-of-birth-container">
                        <GradientBorder className="date-select">
                            <Select
                                placeholder={t('year', "Year")}
                                className="base-select-input"
                                {...formik.getFieldProps("year")}>
                                {
                                    years.map((year) => {
                                        return (
                                            <option
                                                key={year}
                                                value={year}
                                                style={{
                                                    color: 'black'
                                                }}>{year}</option>)
                                    })
                                }
                            </Select>
                            {formik.errors.year && formik.touched.year && (
                                <ErrorTooltipIcon text={undefined} formik={formik} fieldName={"year"} />
                            )}
                        </GradientBorder>
                        <GradientBorder mb="24px" className="date-select">
                            <Select
                                placeholder={t('month', "Month")}
                                className="base-select-input"
                                {...formik.getFieldProps("month")}>
                                {
                                    months.map((month) => {
                                        return (<option
                                            key={month}
                                            value={month}
                                            style={{
                                                color: 'black'
                                            }}>{month}</option>)
                                    })
                                }
                            </Select>
                            {formik.errors.month && formik.touched.month && (
                                <ErrorTooltipIcon text={undefined} formik={formik} fieldName={"month"} />
                            )}
                        </GradientBorder>
                        <GradientBorder mb="24px" className="date-select">
                            <Flex>
                                <Select
                                    placeholder={t('day', "Day")}
                                    className="base-select-input"
                                    {...formik.getFieldProps("day")}>
                                    {
                                        days.map((day) => {
                                            return (<option
                                                key={day}
                                                value={day}
                                                style={{
                                                    color: 'black'
                                                }}>{day}</option>)
                                        })
                                    }
                                </Select>
                                {formik.errors.day && formik.touched.day && (
                                    <ErrorTooltipIcon formik={formik} text={undefined} fieldName={"day"} />
                                )}
                            </Flex>
                        </GradientBorder>

                    </Flex>
                </GradientBorder>
                {isDateError && (
                    <ErrorTooltipIcon text={"Invalid date"} formik={formik} fieldName={"day"} />
                )}
            </Flex>
        </FormControl>
    )
}