import { createSelector } from "@reduxjs/toolkit";

const selectSignInSession = (state: any) => state.signInSession;

export interface SignInSessionSelector {
    loggedIn: boolean,
    lastServerDateTime: string,
    action_uuid: string
}

export const selectUserLoggedIn = createSelector(
    [selectSignInSession],
    (user: SignInSessionSelector) => user.loggedIn
);

export const selectSignInActionUUIDSelector = createSelector(
    [selectSignInSession],
    (user: SignInSessionSelector) => user.action_uuid
);