import React from "react";
import { Box, Button, Card, CardBody, Flex, HStack, Icon, LinkBox, LinkOverlay, Spacer, Text, VStack } from "@chakra-ui/react";
import { FaArrowCircleRight, FaCheckCircle, FaCog, FaHistory, FaLanguage, FaLock, FaPlug } from "react-icons/fa";
import { UserDashboardResponseModel } from "../../../../modules/models/responses/user/user-dashboard-response.model";
import { useSelector } from "react-redux";
import { selectUserProfileDashboardSelector } from "../../../../modules/models/selectors/userProfileSelector";
import { CloseIcon } from "@chakra-ui/icons";
import { Link, useResolvedPath } from "react-router-dom";

export default function SettingsTabView() {
    const dashboard: UserDashboardResponseModel = useSelector(selectUserProfileDashboardSelector)
    const faEnabled: boolean = dashboard.isTwoFactorAuthenticationEnabled;
    const antiforgeryTokenEnabled: boolean = dashboard.isEmailAntiforgeryTokenEnabled;

    const twoFactorAuthPath = useResolvedPath('security');
    const emailAntiforgeryTokenPath = useResolvedPath('anti-phishing-token');

    return (
        <Card w='md' bg={"linear-gradient(to right, #252799, #712be2)"} color="white">

            <CardBody mt={0} pt={0}>
                <LinkBox as='article' maxW='sm' rounded='md' px={0} mt={4}>
                    <Flex>
                        <LinkOverlay href='#'>
                            <Text className="form-container-heading" style={{
                                marginTop: "8px"
                            }}><Icon as={FaLock} my={"auto"} pt={2} />Security</Text>
                        </LinkOverlay>
                        <Spacer />

                    </Flex>
                </LinkBox>
                <LinkBox as='article' maxW='sm' rounded='md' px={2} mt={0} >
                    <Flex>
                        <VStack marginTop={"auto"} marginBottom={"auto"}>
                            <HStack as={Link} to={twoFactorAuthPath}>
                                <Icon as={faEnabled ? FaCheckCircle : CloseIcon} color={faEnabled ? "green.500" : "red.500"} fontSize="18px" mr={2} />
                                <Box>
                                    <Text fontSize="16px">Security</Text>
                                    <Text fontSize="12px">Click here to see the details</Text>
                                </Box>
                            </HStack>
                        </VStack>

                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>

                <LinkBox as='article' maxW='sm' rounded='md' px={2} mt={4} >
                    <Flex>
                        <VStack>
                            <HStack as={Link} to={emailAntiforgeryTokenPath}>
                                <Icon as={antiforgeryTokenEnabled ? FaCheckCircle : CloseIcon} color={antiforgeryTokenEnabled ? "green.500" : "red.500"} fontSize="18px" mr={2} />
                                <Box>
                                    <Text fontSize="16px">Anti-Phishing Code {antiforgeryTokenEnabled ? "Enabled" : "Disabled"}</Text>
                                    <Text fontSize="12px">Click here to see the details</Text>
                                </Box>
                            </HStack>
                        </VStack>

                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>

                <LinkBox as='article' maxW='sm' rounded='md' px={2} mt={4} >
                    <Flex>
                        <VStack>
                            <HStack as="a" href="#">
                                <Icon as={FaHistory} fontSize="18px" mr={2} />
                                <Box>
                                    <Text fontSize="16px">Account Activity</Text>
                                    <Text fontSize="12px">Last login <strong>{dashboard.lastLoginDate ?? ""}</strong></Text>
                                </Box>
                            </HStack>
                        </VStack>

                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>

                <LinkBox as='article' maxW='sm' rounded='md' px={2} mt={4} >
                    <Flex>
                        <VStack>
                            <HStack as="a" href="#">
                                <Icon as={FaPlug} fontSize="18px" mr={2} />
                                <Box>
                                    <Text fontSize="16px">Application and sites</Text>
                                    <Text fontSize="12px">See all the applications you have granted access</Text>
                                </Box>
                            </HStack>
                        </VStack>

                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>
                <LinkBox as='article' maxW='sm' rounded='md' px={0} mt={4}>
                    <Flex>
                        <LinkOverlay href='#'>
                            <Text className="form-container-heading" style={{
                                marginTop: "8px"
                            }}><Icon as={FaCog} my={"auto"} pt={2} /> Settings</Text>
                        </LinkOverlay>
                        <Spacer />
                    </Flex>
                </LinkBox>
                <LinkBox as='article' maxW='sm' rounded='md' px={2} mt={4} >
                    <Flex>
                        <VStack>
                            <HStack as="a" href="#">
                                <Icon as={FaLanguage} fontSize="18px" mr={2} />
                                <Box>
                                    <Text fontSize="16px">Language and regions</Text>
                                    <Text fontSize="12px">Configure preferred language</Text>
                                </Box>
                            </HStack>
                        </VStack>

                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>
            </CardBody>
        </Card>
    )
}