import { ProgressStep } from "../../../views/Authorization/SignUp/SignUpProgress";
import { SignUpBaseInfoRequestModel } from "../requests/authorization/sign-up/signUpBaseInfoRequest.model";
import { SignUpEmailVerificationRequestModel } from "../requests/authorization/sign-up/signUpEmailVerificationRequest.model";
import { SignUpSetPasswordRequestModel } from "../requests/authorization/sign-up/signUpSetPassword.model";
import { SignUpBaseInfoResponseModel } from "../responses/authorization/sign-up/signUpBaseInfoResponse.model";

export const steps: ProgressStep[] = [
    { title: 'first', description: 'contact_info' },
    { title: 'second', description: 'verify' },
    { title: 'third', description: 'password' },
]

export class UserRegistrationStateModel {
    public basicInfo: SignUpBaseInfoRequestModel = new SignUpBaseInfoRequestModel();
    public basicInfoResponse: SignUpBaseInfoResponseModel = new SignUpBaseInfoResponseModel();

    public emailVerificationRequest: SignUpEmailVerificationRequestModel = new SignUpEmailVerificationRequestModel();
    public setPasswordRequest: SignUpSetPasswordRequestModel = new SignUpSetPasswordRequestModel();

    public currentStep: ProgressStep = steps[0];
    public currentStepIndex: number = 0;

    public registrationFinished: boolean = false;
    public registrationEmailAddress: string = '';

    public getState() {
        return {
            basicInfo: {
                ...this.basicInfo
            },
            basicInfoResponse: {
                ...this.basicInfoResponse
            },
            emailVerificationRequest: {
                ...this.emailVerificationRequest
            },
            setPasswordRequest: {
                ...this.setPasswordRequest
            },
            currentStep: {
                ...this.currentStep
            },
            currentStepIndex: this.currentStepIndex,
            registrationFinished: this.registrationFinished,
            registrationEmailAddress: this.registrationEmailAddress
        }
    }
}