import React from "react";
import { useFormik } from "formik";
import { FormCommonHandlerService } from "../../../formCommonHandler";
import { Box, Button, Card, Center, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import QrCode from "../../../../../components/QrCode/QrCode";
import BaseFormField from "../../../../../components/Forms/BaseFormField";
import { useEffect, useState } from "react";
import { SecurityServiceHandler } from "../../../../../modules/services/account/security-handler.service";
import { useDispatch } from "react-redux";
import { UserRegisterTotpResponse, totpVerificationValidationSchema }
    from "../../../../../modules/models/responses/user/user-register-totp-response.model";
import { BaseUserResponseModel } from "../../../../../modules/models/responses/baseUserResponse.model";
import { Trans, useTranslation } from "react-i18next";

export interface TotpRegisterViewProps {
    response: UserRegisterTotpResponse,
    registerDoneHandler: () => void
}

export default function TotpRegisterView(props: TotpRegisterViewProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useToast();

    const [state, setState] = useState<UserRegisterTotpResponse>({
        alreadyRegistered: props.response.alreadyRegistered,
        encodedTotpUrl: props.response.encodedTotpUrl,
        mnemonic: props.response.mnemonic,
        stateRead: props.response.stateRead
    })

    const formik = useFormik({
        initialValues: {
            firstCode: "",
            secondCode: "",
        },
        validationSchema: totpVerificationValidationSchema,
        onSubmit: (values) => {
            FormCommonHandlerService.build().setFormTouched(formik)

            if (!formik.isValid) return;

            SecurityServiceHandler.build().verifyTotp({
                firstCode: values.firstCode,
                secondCode: values.secondCode
            }, dispatch, toast, (data: BaseUserResponseModel) => {

                if (data.errorCode === 0) {
                    props.registerDoneHandler();
                }

            });
        },
    });


    useEffect(() => {
        FormCommonHandlerService.build().handleCodeChangeNoSubmit(formik, "firstCode");
    }, [formik.values.firstCode]);

    useEffect(() => {
        FormCommonHandlerService.build().handleCodeChangeNoSubmit(formik, "secondCode");
    }, [formik.values.secondCode]);

    useEffect(() => {
        SecurityServiceHandler.build().registerTotp(dispatch, toast, (data) => {
            console.log(data);
            setState({
                ...state,
                alreadyRegistered: data.alreadyRegistered,
                encodedTotpUrl: data.encodedTotpUrl,
                mnemonic: data.mnemonic
            })
        });
    }, []);
    return (
        <form onSubmit={formik.handleSubmit}>
            <Card w='3xl' bg={"linear-gradient(to right, #252799, #712be2)"} color="white" p={4} px={8}>
                <Box
                    px={8}
                    flexWrap="wrap"
                    justifyContent="center"
                    alignItems="center"
                    width={"100%"}
                    color="white">
                    <Text className="form-container-heading" textAlign="center">
                        {t('setup_totp', 'Setup TOTP Code')}
                    </Text>
                    <Text textAlign="center" mb={8}>
                        <Trans
                            i18nKey="totp_credential"
                            defaultValue={'We generated a TOTP credential for you. Scan the code with your authentication app.<0 /><1>Use Google Authenticator, or</1> <3>The Authenticator App</3> created by our team.'}
                            components={{
                                0: <br />,
                                1: <strong />,
                                3: <strong />
                            }}
                        />
                    </Text>

                    <Box mt={8} mb={8}>
                        <Center>
                            <QrCode value={state.encodedTotpUrl} />
                        </Center>
                    </Box>

                    <Text>
                        <Trans i18nKey="passphrase_created"
                            defaultValue={'A passphrase has been created for key recovery.<1> If you lose the QR code, this passphrase is your only way to retrieve the TOTP code.</1>'}
                            components={{
                                1: <strong />
                            }} />
                    </Text>

                    <SimpleGrid columns={4} spacing={4} marginTop={8} marginBottom={8}>
                        {state.mnemonic.map((mnemonic, index) => (
                            <Box key={index} padding={0} textAlign={"center"}>
                                <Text>{index + 1}. {mnemonic}</Text>
                            </Box>
                        ))}
                    </SimpleGrid>

                    <BaseFormField
                        actionButton={undefined}
                        inputProps={{
                            type: "text",
                            textAlign: "center",
                            fontSize: "16px!important",
                            fontWeight: "bold"
                        }}

                        formik={formik}
                        fieldName="firstCode"
                        fieldNameLabel={t('first_code', 'First Verification Code')}
                    />

                    <BaseFormField
                        actionButton={undefined}
                        inputProps={{
                            type: "text",
                            textAlign: "center",
                            fontSize: "16px!important",
                            fontWeight: "bold"
                        }}

                        formik={formik}
                        fieldName="secondCode"
                        fieldNameLabel={t('following_code', 'Following Verification Code')}
                    />

                    <Button
                        mt={4}
                        variant="solid"
                        colorScheme="orange"
                        className="form-button"
                        py={6}
                        type="submit">
                        {t('verify', 'Verify')}
                    </Button>
                </Box>

            </Card>
        </form>
    )
}