import React from "react";
import {
    Box,
    Flex,
    Button,
    Text,
    Divider,
    Spacer,
    useToast
} from "@chakra-ui/react";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import '../../../styles/forms.scss';
import { selectUserSignInSelector } from "../../../modules/models/selectors/userSignInSelector";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import BaseFormField from "../../../components/Forms/BaseFormField";
import { SignInRequestModel } from "../../../modules/models/requests/authorization/sign-in/signInRequest.model";
import { SignInHandlerService } from "../../../modules/services/sign-in/sign-in.handler.service";
import { SignInResponseModel } from "../../../modules/models/responses/authorization/sign-in/signInResponse.model";
import { clear } from "../../../modules/stores/userSignInSlice";
import { FormCommonHandlerService } from "../formCommonHandler";
import { selectSignInActionUUIDSelector } from "../../../modules/models/selectors/signInSessionSelector";
import { useTranslation } from "react-i18next";

export default function PasswordSignInView() {
    const userSignInRequest = useSelector(selectUserSignInSelector)
    const actionUUID = useSelector(selectSignInActionUUIDSelector)

    const dispatch = useDispatch()
    const toast = useToast()
    const navigate = useNavigate();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: { ...userSignInRequest },
        validationSchema: SignInRequestModel.validationSchema(),
        onSubmit: (values) => {
            FormCommonHandlerService.build().setFormTouched(formik)

            if (!formik.isValid) return;
            console.log(values)
            SignInHandlerService.build().signIn(values, dispatch, toast, (data: SignInResponseModel) => {
                if (!actionUUID) {
                    dispatch(clear())
                    navigate("/dashboard")
                    return;
                }

                console.log(`/grant_scopes?action_uuid=${actionUUID}`)
                navigate(`/grant_scopes`) // ?action_uuid=${actionUUID}
                dispatch(clear())
            })
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                width={"100%"}
            >
                <Text className="form-container-heading">{t('sign_in', "Sign In")}</Text>
                <BaseFormField
                    actionButton={undefined}
                    inputProps={{
                        type: "text"
                    }}
                    formik={formik}
                    fieldName="name"
                    fieldNameLabel={t('username_or_email', "Username or Email")}
                />

                <BaseFormField
                    actionButton={undefined}
                    inputProps={{
                        type: "password"
                    }}
                    formik={formik}
                    fieldName="password"
                    fieldNameLabel={t('password', "Password")}
                />

                <Button variant="link" className="form-button" mt={2} as={RouterLink} to="/recover-password">
                    <Flex direction={"column"}>
                        <Text textAlign={"center"}>{t('forgot_password', 'Forgot Your Password?')}</Text>
                        <Spacer />
                        <Text textAlign={"center"} mt={1}>{t('recover_now', "Recover it now!")}</Text>
                    </Flex>
                </Button>

                <Button
                    variant="solid"
                    className="form-button"
                    py={6}
                    mt={8}
                    type="submit"
                >
                    {t('sign_in', "Sign In")}
                </Button>

                <Divider my={8} width={"80%"} />

                <Button
                    variant="solid"
                    colorScheme="blue"
                    className="form-button"
                    py={6}
                    as={RouterLink} to="/sign-up"
                >
                    {t('create_an_account', "Create an account")}
                </Button>
            </Box>
        </form>
    );
}