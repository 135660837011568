import React from "react";
import {
    Box,
    VStack,
    Icon,
    Spacer,
    useDisclosure,
    Text,
    Button
} from '@chakra-ui/react';
import { FaCog, FaArrowCircleLeft, FaArrowCircleRight, FaUser, FaLock, FaSignOutAlt, FaIdCard, FaPlug } from 'react-icons/fa';
import { selectUserLoggedIn } from '../../modules/models/selectors/signInSessionSelector';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../modules/stores/signInSessionSlice';
import { Link, useResolvedPath } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Sidebar = () => {
    const { t } = useTranslation();
    const { isOpen, onToggle } = useDisclosure();
    const userLoggedIn = useSelector(selectUserLoggedIn)
    const dispatch = useDispatch()

    const dashboardPath = useResolvedPath('');
    const securityPath = useResolvedPath('security');

    if (!userLoggedIn) return (<></>);

    return (
        <Box
            as="aside"
            w={isOpen ? '20vw !important' : '25px'}
            bg="linear-gradient(to bottom, #161c297a, #252799ad)"
            color="white"
            boxShadow="lg"
            transition="width 0.2s ease"
        >
            <VStack spacing="0" align="stretch" p={2}>
                <Button
                    variant={"ghost"}
                    color="white"
                    fontSize="32px"
                    width="50px"
                    onClick={onToggle}>
                    {isOpen ? <Icon as={FaArrowCircleLeft} /> : <Icon as={FaArrowCircleRight} />}
                </Button>
                {isOpen && (
                    <>
                        <Button
                            aria-label="Dashboard"
                            as={Link}
                            to={dashboardPath}
                            variant="ghost"
                            color="white"
                            height={12}>
                            <Icon as={FaUser} />
                            <Text ml={2}>{t('dashboard', 'Dashboard')}</Text>
                        </Button>

                        <Button
                            aria-label="Security"
                            variant="ghost"
                            color="white"
                            as={Link}
                            to={securityPath}
                            height={12}>
                            <Icon as={FaLock} />
                            <Text ml={2}>{t('security', 'Security')}</Text>
                        </Button>

                        <Button
                            aria-label="Identification"
                            variant="ghost"
                            color="white"
                            height={12}>
                            <Icon as={FaIdCard} />
                            <Text ml={2}>{t('identification', 'Identification')}</Text>
                        </Button>

                        <Button
                            aria-label="Application and sites"
                            variant="ghost"
                            color="white"
                            height={12}>
                            <Icon as={FaPlug} />
                            <Text ml={2}>{t('application_and_sites', 'Applications and sites')}</Text>
                        </Button>

                        <Button
                            aria-label="Settings"
                            variant="ghost"
                            color="white"
                            height={12}>
                            <Icon as={FaCog} />
                            <Text ml={2}>{t('settings', 'Settings')}</Text>
                        </Button>



                    </>
                )}

                {isOpen &&
                    <hr style={{
                        width: "85%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "0px",
                        marginTop: "16px"
                    }} />
                }

                {isOpen && (
                    <>
                        <Button
                            mt={3}
                            aria-label="Home"
                            variant="ghost"
                            color="white"
                            height={12}
                            onClick={() => {
                                dispatch(logout())
                            }}>
                            <Icon as={FaSignOutAlt} />
                            <Text ml={2}>{t('logout', 'Logout')}</Text>
                        </Button>
                    </>
                )}

            </VStack>
            <Spacer />
        </Box>
    );
};

export default Sidebar;
