import React from "react";
import { Box, Flex, Grid, GridItem, Heading, Link, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { FaLinkedin, FaFacebook, FaTwitter } from "react-icons/fa";

function TopFooter() {
    const templateColumns = useBreakpointValue({ base: "1fr", sm: "1fr", md: "repeat(3, 1fr)", lg: "repeat(8, 1fr)" });

    return (<Grid
        gap={12}
        as="nav"
        templateColumns={templateColumns}
        color="white"
        p="16"
        px={16}
        alignItems="center" >
        <GridItem colSpan={{ base: 1, md: 3 }} w="100%">
            <Box textAlign={{
                base: "center",
                md: "start"
            }}>
                <Heading fontSize="2xl" fontWeight="bold" pb={4}>
                    X-Tour
                </Heading>
                <Text>Org. nr. PL 6312716094 VAT</Text>
                <Text fontSize={"sm"}>X-Tour Sp. z o.o.</Text>
                <Text fontSize={"sm"}>Ul. Stanisława Konarskiego 18/C, 44-100 Gliwice</Text>
            </Box>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }} w="100%">
            <VStack align={
                {
                    base: "center",
                    md: "start"
                }
            }>
                <Text fontWeight="bold">Products</Text>
                <hr
                    style={{
                        background: "linear-gradient(to right, #3388FF, #8A2BE2)",
                        height: "2px",
                        width: "100%",
                        border: "none"
                    }}
                />

                <Link href="#" mt={4}>Pulse</Link>
                <Link href="#">Fantasy Funds</Link>
                <Link href="#">Live Center</Link>
            </VStack>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }} w="100%" justifySelf="flex-end">
            <VStack align={
                {
                    base: "center",
                    md: "start"
                }
            }>
                <Text fontWeight="bold">Use Cases</Text>
                <hr
                    style={{
                        background: "linear-gradient(to right, #3388FF, #8A2BE2)",
                        height: "2px",
                        width: "100%",
                        border: "none"
                    }}
                />
                <Link href="#" mt={4}>Media & Publishers</Link>
                <Link href="#">Financial Services</Link>
                <Link href="#">Blockchain</Link>
            </VStack>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }} w="100%" justifySelf="flex-end">
            <VStack align={
                {
                    base: "center",
                    md: "start"
                }
            }>
                <Text fontWeight="bold">Pricing</Text>
                <hr
                    style={{
                        background: "linear-gradient(to right, #3388FF, #8A2BE2)",
                        height: "2px",
                        width: "100%",
                        border: "none"
                    }}
                />
                <Link href="#" mt={4}>Documents</Link>
                <Link href="#">Option 2</Link>
                <Link href="#">Live Center</Link>
            </VStack>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }} w="100%" justifySelf="flex-end">
            <VStack align={
                {
                    base: "center",
                    md: "start"
                }
            }>
                <Text fontWeight="bold">Resources</Text>
                <hr
                    style={{
                        background: "linear-gradient(to right, #3388FF, #8A2BE2)",
                        height: "2px",
                        width: "100%",
                        border: "none"
                    }}
                />
                <Link href="#" mt={4}>Blog</Link>
                <Link href="#">Case Studies</Link>
                <Link href="#">Events</Link>
            </VStack>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1 }} w="100%" justifySelf="flex-end">
            <VStack align={
                {
                    base: "center",
                    md: "start"
                }
            }>
                <Text fontWeight="bold">Company</Text>
                <hr
                    style={{
                        background: "linear-gradient(to right, #3388FF, #8A2BE2)",
                        height: "2px",
                        width: "100%",
                        border: "none"
                    }}
                />
                <Link href="#" mt={4}>About Us</Link>
                <Link href="#">Careers</Link>
                <Link href="#">Contact Us</Link>
            </VStack>
        </GridItem>
    </Grid >

    );
}

function BottomFooter(props: any) {
    const gridTemplateColumns = useBreakpointValue({ base: 'repeat(1, 1fr)', md: 'repeat(6, 1fr)' });
    const colSpan = useBreakpointValue({ base: 1, md: 3 });


    return (
        <Grid
            gap={4} // Reduced gap for smaller screens
            as="nav"
            templateColumns={gridTemplateColumns} // Adjusted for responsiveness
            color="white"
            p={8} // Reduced padding for smaller screens
            alignItems="center">
            <GridItem colSpan={colSpan} w="100%">
                <Flex direction={['column', 'row']} alignItems={"center"} justifyContent={"flex-start"}>
                    <Box px={4}>
                        <Text fontSize={"sm"} textAlign={"center"}>© 2023 X-Tour Sp. z o. o.</Text>
                    </Box>
                    <Link px={4} isTruncated>
                        <Text fontSize={"sm"} textAlign={"center"}>Terms & Conditions</Text>
                    </Link>
                    <Link px={4} isTruncated>
                        <Text fontSize={"sm"} textAlign={"center"}>Privacy Policy</Text>
                    </Link>
                </Flex>
            </GridItem>

            <GridItem colSpan={colSpan} w="100%" justifySelf={["center", "flex-end"]}>
                <Flex alignItems="center" justifyContent={{
                    base: "center",
                    md: "flex-end"
                }} flexDirection={['row', 'row']}>
                    <IconButton
                        as="a"
                        href="https://www.linkedin.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="LinkedIn"
                        icon={<FaLinkedin />}
                        colorScheme="linkedin"
                        variant={"ghost"}
                        size="lg"
                        mx={1}
                    />
                    <IconButton
                        as="a"
                        href="https://www.twitter.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Twitter"
                        icon={<FaTwitter />}
                        colorScheme="twitter"
                        size="lg"
                        variant={"ghost"}
                        mx={1}
                    />
                    <IconButton
                        as="a"
                        href="https://www.facebook.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="LinkedIn"
                        icon={<FaFacebook />}
                        colorScheme="facebook"
                        size="lg"
                        variant={"ghost"}
                        mx={1}
                    />
                </Flex>
            </GridItem>
        </Grid>
    );
}

export default function Footer(props: any) {
    return (
        <div style={{
            borderTop: "2px solid #2C5282",
            backgroundColor: "#031536"
        }}>
            <TopFooter />
            <hr style={{
                width: "90%",
                margin: "auto"
            }} />
            <BottomFooter />
        </div>)
}
