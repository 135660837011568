import React from "react";
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { selectUserLoggedIn } from '../../modules/models/selectors/signInSessionSelector';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function PrivateRoute({ element, redirectPath }: any) {
  const isAuthenticated = useSelector(selectUserLoggedIn)
  if (!isAuthenticated) return <Navigate to={redirectPath} />;
  return element;
}

export function RoleBasedRoute({ element, roles, redirectPath }: any) {
  const isAuthenticated = useSelector(selectUserLoggedIn)

  if (!isAuthenticated) return <Navigate to={redirectPath} />;
  return element;

  /* check and handle roles */
}

export function PublicRouteWithRedirectToDashboard({ element, redirectPath }: any) {
  const isAuthenticated = useSelector(selectUserLoggedIn)
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) navigate(redirectPath);
  }, []);

  return element;
}

export function PublicRouteSignIn({ element, redirectPath, authPath }: any) {
  const isAuthenticated = useSelector(selectUserLoggedIn)
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const action_uuid = searchParams.get('action_uuid');
    if (isAuthenticated && !action_uuid) {
      navigate(redirectPath);
    } else if (isAuthenticated) navigate(authPath);
  }, []);

  return element;
}