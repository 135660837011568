import React from "react";
import { Box, Button, Card, CardBody, Flex, HStack, Icon, LinkBox, Spacer, Text, VStack, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaArrowCircleRight, FaHistory, FaPlug } from "react-icons/fa";
import { SecurityServiceHandler } from "../../../../modules/services/account/security-handler.service";
import { useDispatch } from "react-redux";
import { UserSecurityConfigResponse } from "../../../../modules/models/responses/user/user-security-config-response.model";
import ConditionalIcon from "../../../../components/ConditionalIcon";
import { Link, useResolvedPath } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import ChangePasswordModalView from "./Password/ChangePasswordModalView";
import VerificationModalView from "../../Verification/VerificationModalView";
import { ChangePasswordHandlerService } from "../../../../modules/services/account/change-password-handler.service";
import { VerificationActionResponseModel } from "../../../../modules/models/responses/authorization/verifyActionResponse.model";
import { GlobalResponseModel } from "../../../../modules/models/responses/globalResponse.model";

export default function SecurityView() {
    const { isOpen: isOpenSetPasswordModal, onOpen: onOpenSetPasswordModal, onClose: onCloseSetPasswordModal } = useDisclosure()
    const { isOpen: isOpenVerificationModal, onOpen: onOpenVerificationModal, onClose: onCloseVerificationModal } = useDisclosure()
    const [ passwordVerification, setPasswordVerification] = useState<VerificationActionResponseModel>();
    const dispatch = useDispatch();
    const toast = useToast();
    const { t } = useTranslation();
    const securityPath = useResolvedPath('totp');
    const emailPath = useResolvedPath('email');

    const [configData, setConfigData] = useState({
        twoFactorAuth: false,
        totp: false,
        emailAuth: false,
        phoneAuth: false,
        passwordAuth: false,
        antiPhishingCode: false
    })

    useEffect(() => {
        SecurityServiceHandler.build().info(dispatch, toast, (data: UserSecurityConfigResponse) => {
            console.log(data);
            setConfigData({
                ...data
            });
        });
    }, []);

    return (
        <Card w='2xl' bg={"linear-gradient(to right, #252799, #712be2)"} color="white" p={8}>
            <CardBody mt={0} pt={0} pb={8}>
                <LinkBox as='article' maxW='2xl' rounded='md' px={0} mt={4}>
                    <Flex>
                        <Text className="form-container-heading" style={{
                            marginTop: "8px",
                            marginBottom: "0"
                        }}>
                            <Trans
                                i18nKey={"security"}
                                defaultValue={"Security"} />
                        </Text>
                        <Spacer />
                    </Flex>
                    <Flex mt={0} pt={0}>
                        <Text fontSize={"14px"}>
                            <Trans
                                i18nKey={"to_keep_secure_green"}
                                defaultValue={"To keep your account secure ensure all categories are green."} />
                        </Text>
                    </Flex>
                    <Flex mt={4}>
                        <Spacer />
                        <Box display="flex" alignItems="center" justifyContent="start">
                            <ConditionalIcon condition={configData?.twoFactorAuth} fontSize={"18px"} />
                            <Text fontSize="14px">
                                <Trans
                                    i18nKey={"two_fa_header"}
                                    defaultValue={"Two Factor Authentication (2FA)"} />
                            </Text>
                        </Box>
                        <Spacer />
                        <Box display="flex" alignItems="center" justifyContent="start" ml={4}>
                            <ConditionalIcon condition={configData?.antiPhishingCode} fontSize={"18px"} />
                            <Text fontSize="14px">
                                <Trans
                                    i18nKey={"anti_phishing_code"}
                                    defaultValue={"Anti-Phishing Code"} />
                            </Text>
                        </Box>
                        <Spacer />
                    </Flex>
                </LinkBox>
                <LinkBox as='article' maxW='2xl' rounded='md' px={0} mt={6}>
                    <Flex>
                        <Text style={{
                            marginTop: "8px",
                            marginBottom: "0",
                            fontSize: "18px",
                            fontWeight: "bold"
                        }}>
                            <Trans
                                i18nKey={"two_fa_header"}
                                defaultValue={"Two Factor Authentication (2FA)"} />
                        </Text>
                        <Spacer />
                    </Flex>
                </LinkBox>
                <LinkBox as='article' maxW='2xl' rounded='md' px={2} mt={4} >
                    <Flex>
                        <VStack marginTop={"auto"} marginBottom={"auto"}>
                            <HStack as={Link} to={securityPath} >
                                <ConditionalIcon condition={configData?.totp} fontSize={"18px"} />
                                <Box>
                                    <Text fontSize="16px" fontWeight={"bold"}>
                                        <Trans
                                            i18nKey={"authenticator_app"}
                                            defaultValue={"Authenticator App"} />
                                    </Text>
                                    <Text fontSize="12px">
                                        <Trans
                                            i18nKey={"use_authenticator_app"}
                                            defaultValue={"Use <1>X-Tour Authenticator</1> or Google Authenticator."}
                                            components={[<strong></strong>]}
                                        />
                                    </Text>
                                </Box>
                            </HStack>
                        </VStack>

                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>

                <LinkBox as='article' maxW='2xl' rounded='md' px={2} mt={4} >
                    <Flex>
                        <VStack marginTop={"auto"} marginBottom={"auto"}>
                            <HStack as={Link} to={emailPath}>
                                <ConditionalIcon condition={configData?.emailAuth} fontSize={"18px"} />
                                <Box>
                                    <Text fontSize="16px" fontWeight={"bold"}>
                                        <Trans
                                            i18nKey={"email"}
                                            defaultValue={"Email"} />
                                    </Text>
                                    <Text fontSize="12px">
                                        <Trans
                                            i18nKey={"email_to_protect_account"}
                                            defaultValue={"Use your email to protect you account."} />
                                    </Text>
                                </Box>
                            </HStack>
                        </VStack>
                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>

                <LinkBox as='article' maxW='2xl' rounded='md' px={2} mt={4} >
                    <Flex>
                        <VStack marginTop={"auto"} marginBottom={"auto"}>
                            <HStack as={Link} >
                                <ConditionalIcon condition={configData?.phoneAuth} fontSize={"18px"} />
                                <Box>
                                    <Text fontSize="16px" fontWeight={"bold"}>
                                        <Trans
                                            i18nKey={"phone_number"}
                                            defaultValue={"Phone Number"} />
                                    </Text>
                                    <Text fontSize="12px">
                                        <Trans
                                            i18nKey={"phone_number_to_protect_account"}
                                            defaultValue={"Use your phone number to protect you account."} />
                                    </Text>
                                </Box>
                            </HStack>
                        </VStack>

                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>

                <LinkBox as='article' maxW='2xl' rounded='md' px={2} mt={4} >
                    <Flex>
                        <VStack marginTop={"auto"} marginBottom={"auto"}>
                            <HStack as={Link} >
                                <ConditionalIcon condition={configData?.passwordAuth} fontSize={"18px"} />
                                <Box>
                                    <Text fontSize="16px" fontWeight={"bold"}>
                                        <Trans
                                            i18nKey={"password"}
                                            defaultValue={"Password"} />
                                    </Text>
                                    <Text fontSize="12px">
                                        <Trans
                                            i18nKey={"password_to_protect_account"}
                                            defaultValue={"Use your password to protect you account."} />
                                    </Text>
                                </Box>
                            </HStack>
                        </VStack>

                            <Spacer />
                            <Button style={{
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                }}
                                mx={0}
                                px={8}
                                variant={"solid"}
                                onClick={() => {
                                    ChangePasswordHandlerService.build().requestVerify(dispatch, toast, (data: VerificationActionResponseModel) => {
                                        if (data.statusCode !== 0) return;

                                        setPasswordVerification(data);
                                        onOpenVerificationModal();
                                    });
                                }}>
                                <Trans
                                    i18nKey={"change_password"}
                                    defaultValue={"Change Password"}
                                />
                        </Button>
                    </Flex>
                </LinkBox>


                <LinkBox as='article' maxW='2xl' rounded='md' px={0} mt={6}>
                    <Flex>
                        <Text style={{
                            marginTop: "8px",
                            marginBottom: "0",
                            fontSize: "18px",
                            fontWeight: "bold"
                        }}>
                            <Trans
                                i18nKey={"advanced_settings"}
                                defaultValue={"Advanced Settings"} />
                        </Text>
                        <Spacer />
                    </Flex>
                </LinkBox>

                <LinkBox as='article' maxW='2xl' rounded='md' px={2} mt={4} >
                    <Flex>
                        <VStack marginTop={"auto"} marginBottom={"auto"}>
                            <HStack as={Link} to={emailPath}>
                                <ConditionalIcon condition={configData?.antiPhishingCode} fontSize={"18px"} />
                                <Box>
                                    <Text fontSize="16px" fontWeight={"bold"}>
                                        <Trans
                                            i18nKey={"anti_phishing_code"}
                                            defaultValue={"Anti-Phishing Code"} />
                                    </Text>
                                    <Text fontSize="12px">
                                        <Trans
                                            i18nKey={"protect_from_phishing"}
                                            defaultValue={"Protect your account from phishing attacks, make sure that emails are coming from us."} />
                                    </Text>
                                </Box>
                            </HStack>
                        </VStack>

                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>
                <LinkBox as='article' maxW='xlg' rounded='md' px={2} mt={4} >
                    <Flex>
                        <VStack>
                            <HStack as="a" href="#">
                                <Icon as={FaPlug} fontSize="18px" mr={2} />
                                <Box>
                                    <Text fontSize="16px">
                                        <Trans
                                            i18nKey={"application_and_sites"}
                                            defaultValue={"Applications and Sites"} />
                                    </Text>
                                    <Text fontSize="12px">
                                        <Trans
                                            i18nKey={"see_apps_you_granted_access"}
                                            defaultValue={"See all the applications you have granted access"} />
                                    </Text>
                                </Box>
                            </HStack>
                        </VStack>

                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>

                <LinkBox as='article' maxW='2xl' rounded='md' px={0} mt={6}>
                    <Flex>
                        <Text style={{
                            marginTop: "8px",
                            marginBottom: "0",
                            fontSize: "18px",
                            fontWeight: "bold"
                        }}>
                            <Trans
                                i18nKey={"devices_and_activity"}
                                defaultValue={"Devices and Activity"} />
                        </Text>
                        <Spacer />
                    </Flex>
                </LinkBox>

                <LinkBox as='article' maxW='xlg' rounded='md' px={2} mt={4} >
                    <Flex>
                        <VStack>
                            <HStack as="a" href="#">
                                <Icon as={FaHistory} fontSize="18px" mr={2} />
                                <Box>
                                    <Text fontSize="16px">
                                        <Trans
                                            i18nKey={"account_activity"}
                                            defaultValue={"Account Activity"} />
                                    </Text>
                                    <Text fontSize="12px">
                                        <Trans
                                            i18nKey={"last_login"}
                                            defaultValue={"Last Login"} />
                                    </Text>
                                </Box>
                            </HStack>
                        </VStack>

                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>
            </CardBody>
            {
                !!passwordVerification && 
                <Box>
                    <ChangePasswordModalView
                        isOpen={isOpenSetPasswordModal}
                        onClose={onCloseSetPasswordModal}
                        setPassword={(password: string) => {
                            ChangePasswordHandlerService.build().setPassword({
                                password: password,
                                setPasswordActionId: passwordVerification.actionUUID
                            }, dispatch, toast, (data: GlobalResponseModel) => {
                                if (data.statusCode !== 0) return;

                                SecurityServiceHandler.build().info(dispatch, toast, (data: UserSecurityConfigResponse) => {
                                    console.log(data);
                                    setConfigData({
                                        ...data
                                    });
                                    onCloseSetPasswordModal()
                                });
                            })
                        }}/>
                    <VerificationModalView
                            actionType={passwordVerification.actionType}
                            checkCode={(code: string) => {
                                ChangePasswordHandlerService.build().verify({
                                    actionId: passwordVerification.actionUUID,
                                    code: code
                                }, dispatch, toast, (data: VerificationActionResponseModel) => {
                                    setPasswordVerification(data)
                                    onCloseVerificationModal()
                                    onOpenSetPasswordModal()
                                })
                            }}
                            email=""
                            refreshCode={() => {
                                ChangePasswordHandlerService.build().refreshVerify(passwordVerification.actionUUID, dispatch, toast,
                                    (data: VerificationActionResponseModel) => {
                                        setPasswordVerification(data)
                                    })
                            }}
                            isOpen={isOpenVerificationModal}
                            onClose={onCloseVerificationModal}
                        />
                </Box>
            }
        </Card>
    )
}