import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Text, Button, Container } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import '../../../styles/forms.scss';
import { useTranslation } from "react-i18next";

export default function RecoverPasswordSuccess() {
    const { t } = useTranslation();
    return (
        <Box color="white" px={8} >
            <Box
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                width={"100%"}>
                <Text className="form-container-heading" textAlign={"center"}>{t('success', 'Success')}</Text>
                <Text textAlign={"center"} mb={4} fontWeight={"bold"}>
                    <CheckCircleIcon color="green.500" fontSize="1.5rem" mb={0} mx={2} /> {t('password_changed', "Password has been successfully changed.")}
                </Text>
                <Button
                    variant="solid"
                    className="form-button"
                    py={6}
                    mt={4}
                    as={RouterLink} to="/sign-in">
                    {t('sign_in', 'Sign In')}
                </Button>
            </Box>
        </Box>
    );
}