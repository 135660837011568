import React from "react";
import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Text,
    List,
    ListItem,
    useToast, // Add Select from Chakra UI
} from "@chakra-ui/react";
import '../../../styles/forms.scss';
import { useDispatch, useSelector } from "react-redux";
import { selectUserBasicInfoSelector, userEmailVerficationRequestSelector } from "../../../modules/models/selectors/userRegistrationSelectors";
import { useFormik } from "formik";
import BaseFormField from "../../../components/Forms/BaseFormField";
import { verificationCodeValidationSchema } from "../../../modules/models/requests/authorization/sign-up/signUpEmailVerificationRequest.model";
import { previous } from "../../../modules/stores/userRegistrationSlice";
import { SignUpHandlerService } from "../../../modules/services/sign-up/sign-up.handler.service";
import { FormCommonHandlerService } from "../formCommonHandler";
import { Trans, useTranslation } from "react-i18next";


export default function VerifyEmailAddressForm() {
    const minutes = 5;
    const userBaseInfo = useSelector(selectUserBasicInfoSelector)
    const userEmailVerficationRequest = useSelector(userEmailVerficationRequestSelector)
    const { t, i18n, ready } = useTranslation();

    const [checkedCodes, setCheckedCodes] = useState(new Map());
    const dispatch = useDispatch()
    const toast = useToast()

    const formik = useFormik({
        initialValues: { ...userEmailVerficationRequest },
        validationSchema: verificationCodeValidationSchema,
        onSubmit: (values) => {
            FormCommonHandlerService.build().setFormTouched(formik)

            if (!formik.isValid) return;

            const oldMap = new Map(checkedCodes);
            oldMap.set(values.code, true);
            setCheckedCodes(oldMap)

            SignUpHandlerService.build().verifyEmail(values, dispatch, toast);
        },
    });

    useEffect(() => {
        formik.resetForm({ values: { ...userEmailVerficationRequest } });
    }, [userEmailVerficationRequest]);

    useEffect(() => {
        FormCommonHandlerService.build().handleCodeChange(formik, checkedCodes);
    }, [formik.values.code]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                width={"100%"}>
                <Text className="form-container-heading">{t("email_verification", "Email Verification")}</Text>
                <Text textAlign={"center"} mb={4}>
                    <Trans
                        i18nKey="enter_verification_code"
                        components={{
                            "1": <strong />,
                            "3": <strong />
                        }}
                        defaultValue={"Please enter the <1>6-digit verification code</1> that was sent to your email address. The code is <3>valid for 15 minutes.</3>"}
                    />
                </Text>
                <Text textAlign={"center"} mb={4}>
                    <Trans
                        i18nKey="verification_email_sent"
                        components={{
                            "1": <strong />,
                        }}
                        defaultValue="We sent an email with a verification code to <1>{{email}}</1>"
                        email={userBaseInfo.email}
                    /><br />
                    <Button variant={"ghost"} className="mt4" onClick={
                        () => {
                            dispatch(previous())
                        }}>
                        {t("not_you_quest", "Not you?")}
                    </Button>
                </Text>

                <BaseFormField
                    actionButton={undefined}
                    inputProps={{
                        type: "text",
                        textAlign: "center",
                        fontSize: "16px!important",
                        fontWeight: "bold"
                    }}

                    formik={formik}
                    fieldName="code"
                    fieldNameLabel={t("verification_code", "Verification Code")}
                />

                <Button
                    variant="solid"
                    colorScheme="orange"
                    className="form-button"
                    py={6}
                    type="submit">
                    {t("verify", "Verify")}
                </Button>

                <Button
                    variant="solid"
                    className="form-button"
                    py={6}
                    mt={4}
                    onClick={
                        (event) => {
                            setCheckedCodes(new Map())
                            SignUpHandlerService.build().resendVerificationEmail(userEmailVerficationRequest, dispatch, toast)
                            event.preventDefault()
                        }
                    }>
                    {t("resend_code", "Resend Code")}
                </Button>
            </Box>
            <Box mt={8}>
                <Text>{"Didn't get the code?"}</Text>
                <List styleType="disc" ml={8}>
                    <ListItem>
                        <Text>{t("code_arrive_n_minutes", {
                            defaultValue: "Codes can take up to {{n}} minutes to arrive.",
                            n: minutes
                        })}</Text>
                    </ListItem>
                    <ListItem>
                        <Text>{t("check_spam", "Check your spam folder.")}</Text>
                    </ListItem>
                </List>
            </Box>
        </form>
    )
}