import { createSelector } from "@reduxjs/toolkit";
import { RecoverStateModel } from "../../stores/recoverSlice";

const selectRecover = (state: any) => state.recover;

export const selectRecoveryState = createSelector(
    [selectRecover],
    (recover: RecoverStateModel) => recover
);

export const selectRecoverActionUUID = createSelector(
    [selectRecover],
    (recover: RecoverStateModel) => recover.actionUUID
);