import { Dispatch } from "react";
import { HttpValidatorService } from "../http-validator.service";
import { RecoverAccountService, RecoverAccountStep1RequestModel, SetPasswordRequestModel, RecoverVerifyCodeRequestModel } from "./recover.service";
import { RecoverStateModel } from "../../stores/recoverSlice";
import { BaseUserResponseModel } from "../../models/responses/baseUserResponse.model";
import { GlobalResponseModel } from "../../models/responses/globalResponse.model";

export class RecoverAccountServiceHandler {
    static build() {
        return new RecoverAccountServiceHandler();
    }

    public recoverStepOne(request: RecoverAccountStep1RequestModel, dispatch: Dispatch<any>, toast: any,
        onRecoverStepOne: (data: GlobalResponseModel) => void = (data: GlobalResponseModel) => { }) {
        RecoverAccountService.build().recoverStepOne(request).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: GlobalResponseModel) => {
                console.log(data);
                onRecoverStepOne(data)
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }, (err: any) => {
            console.log("ERROR", err)
        });
    }

    public verifyCode(request: RecoverVerifyCodeRequestModel, dispatch: Dispatch<any>, toast: any,
        onVerify: (data: RecoverStateModel) => void = (data: RecoverStateModel) => { }) {
        RecoverAccountService.build().verifyCode(request).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: RecoverStateModel) => {
                console.log(data);
                onVerify(data)
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }, (err: any) => {
            console.log("ERROR", err)
        });
    }

    public refreshVerifyCode(actionUUID: string, dispatch: Dispatch<any>, toast: any,
        onRefreshVerifyCode: (data: RecoverStateModel) => void = (data: RecoverStateModel) => { }) {
        RecoverAccountService.build().refreshVerifyCode(actionUUID).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: RecoverStateModel) => {
                console.log(data);
                onRefreshVerifyCode(data)
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }, (err: any) => {
            console.log("ERROR", err)
        });
    }

    public setPassword(request: SetPasswordRequestModel, dispatch: Dispatch<any>, toast: any,
        onSetPassword: (data: BaseUserResponseModel) => void = (data: BaseUserResponseModel) => { }) {
        RecoverAccountService.build().setPassword(request).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: BaseUserResponseModel) => {
                console.log(data);
                onSetPassword(data)
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }, (err: any) => {
            console.log("ERROR", err)
        });
    }
}