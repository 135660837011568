import * as Yup from 'yup';

export function isValidDate(day: any, month: any, year: any) {
    const composedDate = new Date(year, month - 1, day); // month is 0-indexed
    return composedDate.getFullYear() === parseInt(year, 10)
        && composedDate.getMonth() === parseInt(month, 10) - 1
        && composedDate.getDate() === parseInt(day, 10);
}

export class SignUpBaseInfoRequestModel {
    public userName: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public birthDate: string = '';
    public email: string = '';
    public day: number = 0;
    public month: number = 0;
    public year: number = 0;

    public static validationSchema = () => Yup.object().shape({
        userName: Yup.string()
            .min(4, 'Username must be at least 4 characters')
            .max(20, 'Username cannot exceed 20 characters')
            .matches(
                /^[a-zA-Z0-9_.-]*$/,
                'Only letters, numbers, - , _ , and . are allowed'
            )
            .required('Username is required'),
        firstName: Yup.string()
            .min(4, 'First name must be at least 4 characters')
            .max(20, 'First name cannot exceed 20 characters')
            .matches(/^\p{L}+$/u,
                'Only letters are allowed'
            )
            .required('First name is required'),
        lastName: Yup.string()
            .min(4, 'Last name must be at least 4 characters')
            .max(20, 'Last name cannot exceed 20 characters')
            .matches(/^([\p{L}-])+$/u,
                'Only letters and - are allowed'
            )
            .required('Last name is required'),
        email: Yup.string().email('Invalid email address').required('Required'),
        day: Yup.number()
            .required('Day is required')
            .min(1, 'Day should be between 1 and 31')
            .max(31, 'Day should be between 1 and 31'),
        month: Yup.number()
            .required('Month is required')
            .min(1, 'Month should be between 1 and 12')
            .max(12, 'Month should be between 1 and 12'),
        year: Yup.number()
            .required('Year is required')
            .min(1900, 'Year should be realistic')
    })
}