import { Dispatch } from "react";
import { GrantScopesService } from "./grant-scopes.service";
import { HttpValidatorService } from "../http-validator.service";
import { UserGetScopesToGrantResponse } from "../../models/responses/user/scopes/user-get-scopes-to-grant-response.model";

export class GrantScopesServiceHandler {

    static build() {
        return new GrantScopesServiceHandler();
    }

    getScopes(action: string, dispatch: Dispatch<any>, toast: any, handle: (data: UserGetScopesToGrantResponse) => void = () => { }) {
        GrantScopesService.build().getScopes(action).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: UserGetScopesToGrantResponse) => {
                handle(data);
            }, () => {
                console.log("ERROR")
            }, dispatch)
        }, (err: any) => {
            console.log("ERRPR", err)
        });
    }
}