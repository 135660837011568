import React from "react";
import { Heading, Button, Grid, GridItem } from "@chakra-ui/react";
import { ArrowLeftIcon } from "@chakra-ui/icons";

interface GoBackButtonProps {
    click: () => void;
    currentIndex: number;
    text: string;
    showBackButton?: boolean;
}

function GoBackButton({ click, currentIndex, text, showBackButton }: GoBackButtonProps) {
    return (
        <Grid
            gap={6}
            as="nav"
            bg="transparent"
            templateColumns='repeat(3, 1fr)'
            color="white"
            alignItems="center">

            <GridItem colSpan={1} w={"100%"}>
                {(currentIndex > 0 && showBackButton) && (
                    <Button
                        leftIcon={<ArrowLeftIcon />}
                        variant="ghost"
                        color={"white"}
                        size="sm"
                        mb={4}
                        onClick={() => {
                            // Handle the button click here
                            // You can use this function to navigate back or perform any other action.
                            click();
                        }}
                    >
                        Back
                    </Button>
                )}
            </GridItem>
            <GridItem colSpan={1} w={"100%"}>
                <Heading fontSize="24px" mb={4} textAlign={"center"}>
                    {text}
                </Heading>
            </GridItem>
            <GridItem colSpan={1} w={"100%"}>
            </GridItem>


        </Grid>
    );
}

export default GoBackButton;
