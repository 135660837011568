
import React from "react";
import { Box, Button, Card, Text } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function TotpSuccessView() {
    const { t } = useTranslation();
    return (
        <Card w='xl' bg={"linear-gradient(to right, #252799, #712be2)"} color="white" p={4} >
            <Box
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                width={"100%"}
                p={8} px={16}>

                <Text textAlign={"center"} mb={4} mt={8} fontWeight={"bold"}>
                    <CheckCircleIcon color="green.500" fontSize="6rem" mb={0} mx={2} />
                </Text>
                <Text className="form-container-heading" textAlign={"center"}>{t('success', 'Success')}</Text>
                <Text textAlign={"center"}>
                    {t('totp_setup', 'TOTP has been setup successfully.')}
                </Text>
                <Button mt={12}
                    variant="solid"
                    className="form-button"
                    py={6}
                    as={RouterLink} to="/dashboard/security">
                    {t('back', 'Back')}
                </Button>
            </Box>
        </Card>
    )
}