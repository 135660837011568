import React from "react";
import { Box, Container, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { selectRecoveryState } from "../../../modules/models/selectors/recoverSelector";
import RecoverPasswordSetPassword from "./RecoverPasswordSetPassword";
import { RecoverStateModel, recoverClear, setRecoverState } from "../../../modules/stores/recoverSlice";
import RecoverPasswordSuccess from "./RecoverPasswordSuccess";
import { RecoverAccountServiceHandler } from "../../../modules/services/recover/recover-handler.service";
import VerificationView from "../Verification/VerificationView";

export default function RecoverPasswordStep2View() {
    const [searchParams] = useSearchParams();
    const recoverStatus = useSelector(selectRecoveryState);
    const dispatch = useDispatch();
    const toast = useToast()
    const { isOpen: isOpenVerificationModal, onOpen: onOpenVerificationModal, onClose: onCloseVerificationModal } = useDisclosure()

    useEffect(() => {
        const actionUUID = searchParams.get('actionUUID');
        const actionType = parseInt(searchParams.get('actionType') ?? "-1");
        const actionDescription = searchParams.get('actionDescription');
        const statusCode = searchParams.get('statusCode');
        const errMessage = searchParams.get('errMessage');
        const errDescription = searchParams.get('errDescription');

        const recoverRequest = {
            actionUUID,
            actionType,
            actionDescription,
            statusCode,
            errMessage,
            errDescription,
            done: false
        };

        dispatch(setRecoverState({
            ...recoverStatus,
            ...recoverRequest
        }))

        if (recoverRequest.actionType == 1) {
            onOpenVerificationModal();
        }

        return () => {
            dispatch(recoverClear())
        }
    }, []);

    return (
        <Container
            className="form-flex-containter"
            centerContent
            py="8"
            px={8}
            my={16}
        >
            <Box color="white" px={8} >
                {recoverStatus.actionType !== 0 &&
                    <VerificationView
                        actionType={recoverStatus.actionType}
                        email=""
                        checkCode={(code: string) => {
                            RecoverAccountServiceHandler.build().verifyCode({
                                actionId: recoverStatus.actionUUID,
                                code: code
                            }, dispatch, toast, (data: RecoverStateModel) => {
                                console.log(data)
                                if (data.statusCode !== 0) {
                                    return;
                                }

                                dispatch(setRecoverState({
                                    ...data
                                }))
                            });
                        }}
                        refreshCode={() => {
                            RecoverAccountServiceHandler.build().refreshVerifyCode(recoverStatus.actionUUID, dispatch, toast,
                                (data: RecoverStateModel) => {
                                    console.log(data)
                                    if (data.statusCode !== 0) {
                                        return;
                                    }

                                    dispatch(setRecoverState({
                                        ...data
                                    }))
                                }
                            );
                        }}
                    />
                }

                {recoverStatus.actionType === 0 && <RecoverPasswordSetPassword recoverState={recoverStatus} />}
                {recoverStatus.done && <RecoverPasswordSuccess />}
            </Box>
        </Container>
    );
}