import React from "react";
import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export interface ConditionalIconProps {
    condition: boolean;
    fontSize: string;
}

export default function ConditionalIcon({ condition, fontSize }: ConditionalIconProps) {
    return (
        <>
            {condition && <Icon as={CheckCircleIcon} color={"green.500"} fontSize={fontSize} mr={2} />}
            {!condition && <Icon as={CloseIcon} color={"red.500"} fontSize={fontSize} mr={2} />}
        </>
    );
}