import React from "react";
import { Button, Card, CardBody, Flex, FormLabel, Icon, IconButton, Input, LinkBox, LinkOverlay, Spacer, Text, Tooltip } from "@chakra-ui/react";
import { FaArrowCircleRight } from "react-icons/fa";
import GradientBorder from "../../../../components/GradientBorder";
import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { UserDashboardResponseModel } from "../../../../modules/models/responses/user/user-dashboard-response.model";
import { selectUserProfileDashboardSelector } from "../../../../modules/models/selectors/userProfileSelector";

export default function ContactInformationTabView() {
    const dashboard: UserDashboardResponseModel = useSelector(selectUserProfileDashboardSelector)
    return (
        <Card w='md' bg={"linear-gradient(to right, #252799, #712be2)"} color="white">

            <CardBody mt={0} pt={0}>
                <LinkBox as='article' maxW='sm' rounded='md' px={0} mt={4}>
                    <Flex>
                        <LinkOverlay href='#'>
                            <Text className="form-container-heading" style={{
                                marginTop: "8px"
                            }}>Contact Information</Text>
                        </LinkOverlay>
                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            Edit
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>

                <FormLabel className={`form-label`}>
                    Email Address
                </FormLabel>
                <Flex>
                    <GradientBorder
                        className={`form-input-gradient-border`}>

                        <Input
                            readOnly={true}
                            type='email'
                            className="form-input"
                            placeholder="Email Address"
                            value={dashboard.userContactData?.emailAddress ?? ""} />
                    </GradientBorder>
                    {dashboard.userContactData?.emailGoodStatus && <Tooltip hasArrow label={"Email Verified"} bg='green.600'>
                        <IconButton
                            my={"auto"}
                            variant={'ghost'}
                            colorScheme="green"
                            aria-label="success-icon"
                            className="p4"
                            icon={<CheckCircleIcon
                                w={6} h={6}
                            />}
                        >
                        </IconButton>
                    </Tooltip>}
                    {!dashboard.userContactData?.emailGoodStatus && <Tooltip hasArrow label={"Email not verified"} bg='red.600'>
                        <IconButton
                            my={"auto"}
                            variant={'ghost'}
                            colorScheme="red"
                            aria-label="error-icon"
                            className="p4"
                            icon={<CloseIcon
                                w={6} h={6}
                            />}
                        >
                        </IconButton>
                    </Tooltip>}
                </Flex>

                <FormLabel className={`form-label`} mt={4}>
                    Phone Number
                </FormLabel>
                <Flex>
                    <GradientBorder
                        className={`form-input-gradient-border`}>

                        <Input
                            readOnly={true}
                            type='phone'
                            className="form-input"
                            placeholder="Phone Number"
                            value={dashboard.userContactData?.phoneNumber ?? ""} />
                    </GradientBorder>
                    {dashboard.userContactData?.phoneNumberGoodStatus && <Tooltip hasArrow label={"Phone number verified"} bg='green.600'>
                        <IconButton
                            my={"auto"}
                            variant={'ghost'}
                            colorScheme="green"
                            aria-label="success-icon"
                            className="p4"
                            icon={<CheckCircleIcon
                                w={6} h={6}
                            />}
                        >
                        </IconButton>
                    </Tooltip>}
                    {!dashboard.userContactData?.phoneNumberGoodStatus && <Tooltip hasArrow label={"Phone number not verified"} bg='red.600'>
                        <IconButton
                            my={"auto"}
                            variant={'ghost'}
                            colorScheme="red"
                            aria-label="error-icon"
                            className="p4"
                            icon={<CloseIcon
                                w={6} h={6}
                            />}
                        >
                        </IconButton>
                    </Tooltip>}
                </Flex>

                <LinkBox as='article' maxW='sm' rounded='md' px={0} mt={4}>
                    <Flex>
                        <LinkOverlay href='#'>
                            <Text className="form-container-heading" style={{
                                marginTop: "8px"
                            }}>Address</Text>
                        </LinkOverlay>
                        <Spacer />
                        <Button style={{
                            marginTop: "auto",
                            marginBottom: "auto"
                        }}
                            mx={0}
                            px={0}
                            colorScheme='white'
                            variant={"ghost"}>
                            Edit
                            <Icon as={FaArrowCircleRight} ml={2} />
                        </Button>
                    </Flex>
                </LinkBox>
                <Text>
                    UL. STANISŁAWA KONARSKIEGO, 18/C<br />
                    Gliwice, Silesia, 44-100 PL
                </Text>
            </CardBody>
        </Card>
    )
}