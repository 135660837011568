import { createSlice } from "@reduxjs/toolkit";

const userProfileSlice = createSlice({
    name: 'userProfileSlice',
    initialState: {
        dashboard: {}
    },
    reducers: {
        setDashboard(state, action) {
            return {
                ...state,
                dashboard: action.payload
            }
        }
    }
});

export const {
    setDashboard,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
