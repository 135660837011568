import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserSignInStateModel } from "../models/stores/userSignInState.model";
import { SignInResponseModel } from "../models/responses/authorization/sign-in/signInResponse.model";
import { RefreshActionResponseModel } from "../models/responses/refreshActionResponse.model";

const userSignInSlice = createSlice({
    name: 'userSignIn',
    initialState: new UserSignInStateModel().getState(),
    reducers: {
        onSimpleSignIn(state, action: PayloadAction<SignInResponseModel>) {
            return {
                ...state,
                signInRequest: {
                    ...state.signInRequest
                },
                signInStep: 1,
                methodType: action.payload.methodType,
                expiresIn: action.payload.actionExpiresIn,
                anonymizedEmail: action.payload.anonymizedEmail,
                signIn2FactorAuthRequest: {
                    actionId: action.payload.actionId,
                    code: ''
                },
            }
        },
        onRefreshCode(state, action: PayloadAction<RefreshActionResponseModel>) {
            return {
                ...state,
                signInRequest: {
                    ...state.signInRequest
                },
                signInStep: 1,
                methodType: state.methodType,
                expiresIn: action.payload.actionExpiresIn,
                signIn2FactorAuthRequest: {
                    actionId: action.payload.newActionId,
                    code: ''
                },
            }
        },
        clear(state) {
            return {
                ...state,
                signInRequest: {
                    ...state.signInRequest
                },
                signInStep: 0,
                methodType: 0,
                expiresIn: 0,
                signIn2FactorAuthRequest: {
                    actionId: '',
                    code: ''
                },
            }
        }
    }
});

export const {
    onSimpleSignIn,
    onRefreshCode,
    clear,
} = userSignInSlice.actions;

export default userSignInSlice.reducer;
