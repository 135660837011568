import { UseToastOptions } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import getCookie, { deleteCookie } from "./cookie.service";
import { login, logout } from "../stores/signInSessionSlice";

function checkAuthorizationCookie(dispatch: any) {
    const isLoggedInCookie = getCookie("IsLoggedIn");
    if (!isLoggedInCookie) {
        deleteCookie("IsLoggedIn");
        dispatch(logout());
    } else {
        dispatch(login())
    }
}


export class HttpValidatorService {
    public static build() {
        return new HttpValidatorService();
    }

    public validateResponse(response: AxiosResponse<any, any>, toast: any,
        handleSuccess: (data: any) => void = (data) => { },
        handleError: () => void = () => { }, dispatch: any) {

        checkAuthorizationCookie(dispatch)


        const toastOptions: UseToastOptions = {
            duration: 2000,
            isClosable: true,
            position: "bottom-right"
        }

        if (response.status >= 200 && response.status < 300) {
            toast({
                title: "An error occurred.",
                description: "Unable to sign up.",
                status: "success",
                ...toastOptions
            })
            handleSuccess(response.data);
            return;
        }

        if (response.status >= 300 && response.status < 400) {
            /* handle redirect */
        }


        if (response.status >= 400 && response.status < 500) {
            /* handle client errors */
            // check if user is logged out

            console.log(response);
            toast({
                title: "An error occurred.",
                description: "Unable to sign up.",
                status: "error",
                ...toastOptions
            })
            handleError();
            return;
        }

        if (response.status >= 500 && response.status < 600) {
            toast({
                title: "An error occurred.",
                description: "Internal Server Error",
                status: "error",
                ...toastOptions
            })
            handleError();
        }
    }
}