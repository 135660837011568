import React from "react";
import { useEffect } from "react";
import {
    Box,
    Container,
    useToast
} from "@chakra-ui/react";

import '../../../styles/forms.scss';
import PasswordSignInView from "./PasswordSignInView";
import GoBackButton from "../../../components/Buttons/GoBackButton";
import { useDispatch, useSelector } from "react-redux";
import { selectUserSignInStepSelector, signIn2FactorAuthRequestSelector } from "../../../modules/models/selectors/userSignInSelector";
import { clear } from "../../../modules/stores/userSignInSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setActionUUID } from "../../../modules/stores/signInSessionSlice";
import VerificationView from "../Verification/VerificationView";
import { SignInHandlerService } from "../../../modules/services/sign-in/sign-in.handler.service";
import { BaseUserResponseModel } from "../../../modules/models/responses/baseUserResponse.model";
import { SignInRefreshCodeModel } from "../../../modules/models/requests/authorization/sign-in/signInRefreshCode.model";
import { selectSignInActionUUIDSelector } from "../../../modules/models/selectors/signInSessionSelector";

function SignInView() {
    const step = useSelector(selectUserSignInStepSelector)
    const requestS = useSelector(signIn2FactorAuthRequestSelector)
    const actionUUID = useSelector(selectSignInActionUUIDSelector)
    const dispatch = useDispatch()
    const toast = useToast()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    console.log(requestS)


    useEffect(() => {
        const action_uuid = searchParams.get('action_uuid');
        dispatch(setActionUUID(action_uuid ?? ''))
        return () => {
            dispatch(clear())
        }
    }, []);

    return (
        <Container
            className="form-flex-containter"
            centerContent
            py="8"
            px={8}
            my={16}
        >
            <Box color="white" px={8} >
                <GoBackButton
                    click={() => {
                        dispatch(clear())
                    }}
                    currentIndex={2}
                    text="X-Tour"
                    showBackButton={step.step !== 0}
                />

                {step.step === 0 && <PasswordSignInView />}
                {step.step === 1 &&
                    <VerificationView
                        actionType={step.methodType}
                        email={requestS.anonymizedEmail}
                        checkCode={(code: string) => {
                            SignInHandlerService.build().secondStep({
                                ...requestS.request,
                                code
                            }, dispatch, toast, (data: BaseUserResponseModel) => {
                                if (!actionUUID) {
                                    dispatch(clear())
                                    navigate("/dashboard")
                                    return;
                                }

                                navigate(`/grant_scopes?action_uuid=${actionUUID}`)
                                dispatch(clear())
                            });
                        }}
                        refreshCode={() => {
                            const request = new SignInRefreshCodeModel()
                            request.actionId = requestS.request.actionId;
                            SignInHandlerService.build().refreshCode(request, dispatch, toast);
                        }}
                    />
                }
            </Box>
        </Container >
    );
}

export default SignInView;
