import React from "react";
import { Flex, FormControl, FormLabel, Input } from "@chakra-ui/react"
import GradientBorder from "../GradientBorder"

import '../../styles/forms.scss'
import { useTranslation } from "react-i18next";


interface DateFieldProps {
    day: string,
    month: string,
    year: string
}

export default function DateDisplayField(props: DateFieldProps) {
    const { t } = useTranslation();
    return (
        <FormControl>
            <FormLabel className="form-label">{t('date_of_birth', 'Date of Birth')}</FormLabel>
            <Flex>
                <GradientBorder className={`form-input-gradient-border`}>
                    <Flex className="date-of-birth-container">
                        <GradientBorder className="date-select">
                            <Input
                                value={props.year}
                                className="base-select-input"
                                readOnly={true} />
                        </GradientBorder>
                        <GradientBorder mb="24px" className="date-select">
                            <Input
                                value={props.month}
                                className="base-select-input"
                                readOnly={true} />
                        </GradientBorder>
                        <GradientBorder mb="24px" className="date-select">
                            <Input
                                value={props.day}
                                className="base-select-input"
                                readOnly={true} />
                        </GradientBorder>
                    </Flex>
                </GradientBorder>
            </Flex>
        </FormControl>
    )
}