import { Box, Button, Card, Flex, Spacer, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormCommonHandlerService } from "../../../formCommonHandler";
import { useFormik } from "formik";
import BaseFormField from "../../../../../components/Forms/BaseFormField";
import { NewEmailCredentialModel } from "../../../../../modules/models/requests/authorization/user/newEmailCredential.model";

export default function EmailAddCredentialView() {
    const dispatch = useDispatch()
    const { t } = useTranslation();


    const formik = useFormik({
        initialValues: {
            email: "",
            antiPhishingToken: ""
        },
        validationSchema: NewEmailCredentialModel.validationSchema(),
        onSubmit: (values) => {
            FormCommonHandlerService.build().setFormTouched(formik)

            if (!formik.isValid) return;
            // submitBaseUserInfo(values, dispatch, toast);
        },
    });


    return (
        <Card width={"100%"} bg={"linear-gradient(to right, #252799, #712be2)"} color="white" py={6} px={8} mt={8}>
            <Text style={{
                marginBottom: "0",
                fontWeight: "bold",
                fontSize: "18px"
            }}>
                <Trans
                    i18nKey="add_new_email_address"
                    defaultValue={"Add New Email Address"} />
            </Text>
            <Text fontSize={"14px"} maxWidth={"80%"}>
                <Trans
                    i18nKey={"add_email_address_com"}
                    defaultValue={"Add new new email address as your safety net if you were to loose access to you current email address. Be careful as the new provided email can provide a way in to the potential attacker."}
                />
            </Text>
            <Flex mt={8}>
                <Box width={"40%"}>
                    <BaseFormField
                        actionButton={undefined}
                        inputProps={{
                            type: "email",
                            maxLength: undefined,
                            minLength: undefined,
                        }}
                        formik={formik}
                        fieldName="email"
                        fieldNameLabel={t('email', "Email Address")}
                    />
                </Box>
                <Box width={"40%"} ml={8}>
                    <BaseFormField
                        actionButton={undefined}
                        inputProps={{
                            type: "password",
                            maxLength: undefined,
                            minLength: undefined,
                        }}
                        formik={formik}
                        fieldName="antiPhishingToken"
                        fieldNameLabel={t('anti_phishing_code', "Anti-Phishing Code")} />
                </Box>
                <Button
                    variant="solid"
                    py={6}
                    mt={7}
                    ml={8}
                    type="submit"
                >
                    {t('add_email_btn', "Add Email Address")}
                </Button>
            </Flex>

        </Card>
    )
}