import React from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Text,
    Container,
    useToast,
} from "@chakra-ui/react";

import '../../../styles/forms.scss';
import { useFormik } from "formik";
import { FormCommonHandlerService } from "../formCommonHandler";
import { RecoverAccountStep1RequestModel } from "../../../modules/services/recover/recover.service";
import { RecoverAccountServiceHandler } from "../../../modules/services/recover/recover-handler.service";
import { useDispatch } from "react-redux";
import { GlobalResponseModel } from "../../../modules/models/responses/globalResponse.model";
import BaseFormField from "../../../components/Forms/BaseFormField";
import { ModeModel } from "./RecoverPasswordBaseView";
import { useTranslation } from "react-i18next";

export default function RecoverPasswordView(props: { setMode: (mode: ModeModel) => void }) {
    const dispatch = useDispatch()
    const toast = useToast()
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: RecoverAccountStep1RequestModel.validationSchema(),
        onSubmit: (values) => {
            FormCommonHandlerService.build().setFormTouched(formik)
            if (!formik.isValid) return;

            RecoverAccountServiceHandler.build().recoverStepOne({
                email: values.email
            }, dispatch, toast, (data: GlobalResponseModel) => {
                console.log(data)
                if (data.statusCode === 0) {
                    props.setMode({
                        mode: 1,
                        email: values.email
                    })
                }
            });
        },
    });

    return (
        <Container
            className="form-flex-containter"
            centerContent
            py="8"
            pb={8}
            my={16}
        >
            <form onSubmit={formik.handleSubmit}>
                <Box color="white" px={8} >
                    <Box>
                        <Box display="flex"
                            flexWrap="wrap"
                            justifyContent="center"
                            alignItems="center"
                            width={"100%"}>
                            <Text className="form-container-heading">{t('forgot_password', "Forgot your password?")}</Text>
                            <Text textAlign={"center"} mb={4}>
                                {t('lost_password_com', "Lost your password? Don't worry, it happens to the best of us. Please enter your email address below, and we'll promptly send you an email containing a secure recovery link.")}
                            </Text>

                            <BaseFormField
                                actionButton={undefined}
                                inputProps={{
                                    type: "email",
                                    maxLength: undefined,
                                    minLength: undefined
                                }}
                                formik={formik}
                                fieldName="email"
                                fieldNameLabel={t('email', "Email")}
                            />

                            <Button
                                variant="solid"
                                colorScheme="orange"
                                className="form-button"
                                py={6}
                                type="submit"
                            >
                                {t('send_recovery_link', "Send Recovery Link")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </form>
        </Container >
    );
}