import React from "react";
import { Box, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SecurityServiceHandler } from "../../../../../modules/services/account/security-handler.service";
import { useDispatch } from "react-redux";
import { UserRegisterTotpResponse } from "../../../../../modules/models/responses/user/user-register-totp-response.model";
import TotpSuccessView from "./TotpSuccessView";
import TotpRegisterView from "./TotpRegisterView";

export default function TOTPSetup() {
    const dispatch = useDispatch();
    const toast = useToast();

    const [state, setState] = useState<UserRegisterTotpResponse>({
        alreadyRegistered: false,
        encodedTotpUrl: "",
        mnemonic: [],
        stateRead: false
    })

    useEffect(() => {
        SecurityServiceHandler.build().registerTotp(dispatch, toast, (data) => {
            console.log(data);
            setState({
                ...state,
                alreadyRegistered: data.alreadyRegistered,
                encodedTotpUrl: data.encodedTotpUrl,
                mnemonic: data.mnemonic,
                stateRead: true
            })
        });
    }, []);

    return (
        <Box>
            {state.alreadyRegistered && <TotpSuccessView />}
            {!state.alreadyRegistered && state.stateRead && <TotpRegisterView
                response={state}
                registerDoneHandler={() => {
                    setState({
                        ...state,
                        alreadyRegistered: true
                    })

                }} />}
        </Box>
    );
}