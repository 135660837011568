import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Button, Text, useToast } from "@chakra-ui/react";
import React from "react";
import BaseFormField from "../../../../../components/Forms/BaseFormField";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { SignUpSetPasswordRequestModel } from "../../../../../modules/models/requests/authorization/sign-up/signUpSetPassword.model";
import { FormCommonHandlerService } from "../../../formCommonHandler";

export interface ChangePasswordViewProps {
    setPassword: (password: string) => void; 
}

export default function ChangePasswordView(props: ChangePasswordViewProps) {
    const dispatch = useDispatch();
    const toast = useToast();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: ""
        },
        validationSchema: SignUpSetPasswordRequestModel.validationSchema(),
        onSubmit: (values) => {
            FormCommonHandlerService.build().setFormTouched(formik)
            if (!formik.isValid) return;
            
            props.setPassword(values.password);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
        <Box display="flex"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            pb={8}>
            <Text className="form-container-heading" textAlign={"center"}>{t('change_password', 'Change Password')}</Text>
            <Text textAlign={"center"} mb={2}>
                {t('password_purpose_com', 'Your password provides you with sign in access to your account and secures your data.')}
            </Text>
            <BaseFormField
                actionButton={undefined}
                inputProps={{
                    type: "password"
                }}
                formik={formik}
                fieldName="password"
                fieldNameLabel={t('password', 'Password')}
            />
            <BaseFormField
                actionButton={undefined}
                inputProps={{
                    type: "password",
                }}
                formik={formik}
                fieldName="confirmPassword"
                fieldNameLabel={t('confirm_passowrd', 'Confirm Password')}
            />

            <Button
                variant="solid"
                className="form-button"
                py={6}
                mt={4}
                type="submit"
            >
                {t('set_password', 'Set Password')}
            </Button>
        </Box>
    </form>
    );
}