import { FormikProps } from "formik";

export class FormCommonHandlerService {
    static build() {
        return new FormCommonHandlerService();
    }

    public setFormTouched(formik: FormikProps<any>) {
        formik.setTouched(
            Object.keys(formik.values).reduce((acc: any, current: any) => {
                acc[current] = true;
                return acc;
            }, {})
        );
    }

    public handleCodeChange(formik: FormikProps<any>, checkedCodes: Map<any, any>) {
        const previousCodeLength = formik.values.code.length;
        const codeRegexPattern = /^[0-9]{0,6}$/;

        // If the code doesn't match the expected pattern, slice the last character
        if (!codeRegexPattern.test(formik.values.code)) {
            formik.setValues({
                ...formik.values,
                code: formik.values.code.slice(0, formik.values.code.length - 1)
            });
        }

        // If the code exceeds the maximum length, do nothing more
        if (previousCodeLength > 6) return;

        // If the code is exactly 6 characters and hasn't been checked before, submit the form
        if (formik.values.code.length === 6) {
            if (checkedCodes.has(formik.values.code)) return;
            formik.submitForm();
        }
    }

    public handleCodeChangeNoSubmit(formik: FormikProps<any>, fieldName: string) {
        const codeRegexPattern = /^[0-9]{0,6}$/;

        // If the code doesn't match the expected pattern, slice the last character
        if (!codeRegexPattern.test(formik.values[fieldName])) {
            formik.setValues({
                ...formik.values,
                [fieldName]: formik.values[fieldName].slice(0, formik.values[fieldName].length - 1)
            });
        }
    }
}