import axios from "axios";

export function getDefaultIdentityApiUrl() {
    const currentProtocol = window.location.protocol;
    const currentHostname = window.location.hostname;
    const currentPort = window.location.port;

    const url = `${currentProtocol}//${currentHostname}:${currentPort}`;

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        return process.env.REACT_APP_IDENTITY_API_URL;
    } else {
        return url;
    }
}

export function getDefaultIdentityApiVersion() {
    return process.env.REACT_APP_IDENTITY_API_VERSION;
}

export function getDefaultIdentityApiUrlWithVersion() {
    return `${getDefaultIdentityApiUrl()}/api/${getDefaultIdentityApiVersion()}`;
}

export function setGlobalClientID() {
    axios.defaults.headers.post['Client-Id'] = process.env.REACT_APP_CLIENT_ID;
    axios.defaults.headers.get['Client-Id'] = process.env.REACT_APP_CLIENT_ID;
    axios.defaults.headers.put['Client-Id'] = process.env.REACT_APP_CLIENT_ID;
    axios.defaults.headers.delete['Client-Id'] = process.env.REACT_APP_CLIENT_ID;

    axios.defaults.withCredentials = true;
}