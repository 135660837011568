import React from "react";
import { RecoverStateModel, setDone } from "../../../modules/stores/recoverSlice";
import { useDispatch } from "react-redux";
import { Box, Button, Text, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { SignUpSetPasswordRequestModel } from "../../../modules/models/requests/authorization/sign-up/signUpSetPassword.model";
import { FormCommonHandlerService } from "../formCommonHandler";
import { CheckCircleIcon } from "@chakra-ui/icons";
import BaseFormField from "../../../components/Forms/BaseFormField";
import { RecoverAccountServiceHandler } from "../../../modules/services/recover/recover-handler.service";
import { BaseUserResponseModel } from "../../../modules/models/responses/baseUserResponse.model";
import { useTranslation } from "react-i18next";

export interface RecoverSetPasswordProps {
    recoverState: RecoverStateModel
}

export default function RecoverPasswordSetPassword(props: RecoverSetPasswordProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const toast = useToast()

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
            setPasswordActionId: props.recoverState.actionUUID
        },
        validationSchema: SignUpSetPasswordRequestModel.validationSchema(),
        onSubmit: (values) => {
            FormCommonHandlerService.build().setFormTouched(formik)
            if (!formik.isValid) return;

            RecoverAccountServiceHandler.build().setPassword({
                setPasswordActionId: props.recoverState.actionUUID,
                password: values.password,
            }, dispatch, toast, (data: BaseUserResponseModel) => {
                if (data.errorCode === 0) {
                    dispatch(setDone())
                    return;
                }
            });
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                width={"100%"}
                pb={8}>
                <Text className="form-container-heading">{t('recover_password', 'Recover Password')}</Text>
                <Text textAlign={"center"} mb={4}>
                    <CheckCircleIcon color="green.500" fontSize="1.5rem" mb={1} mx={2} /> <strong>{"It's you!"}</strong> {t('verification_success', 'You has been successfully verified.')}
                </Text>
                <Text textAlign={"center"} mb={8}>
                    {t('password_purpose_com', 'Your password provides you with sign in access to your account and secures your data.')}
                </Text>
                <BaseFormField
                    actionButton={undefined}
                    inputProps={{
                        type: "password"
                    }}
                    formik={formik}
                    fieldName="password"
                    fieldNameLabel={t('password', 'Password')}
                />
                <BaseFormField
                    actionButton={undefined}
                    inputProps={{
                        type: "password",
                    }}
                    formik={formik}
                    fieldName="confirmPassword"
                    fieldNameLabel={t('confirm_passowrd', 'Confirm Password')}
                />

                <Button
                    variant="solid"
                    className="form-button"
                    py={6}
                    mt={4}
                    type="submit"
                >
                    {t('set_password', 'Set Password')}
                </Button>
            </Box>
        </form>
    );
}