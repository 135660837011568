import React from "react";
import ReactDOM from 'react-dom/client';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useRef } from 'react';

export interface IQrCodeProps {
    value: string;
}

export default function QrCode(props: IQrCodeProps) {
    const containerRef = useRef(null);
    const rootRef = useRef(null);

    useEffect(() => {
        if (containerRef.current && !rootRef.current) {
            (rootRef as any).current = ReactDOM.createRoot(containerRef.current);
            if (!rootRef.current) return;

            (rootRef.current as any).render(
                <QRCodeSVG size={200} value={props.value} />
            );
        } else if (rootRef.current) {
            (rootRef.current as any).render(
                <QRCodeSVG size={200} value={props.value} />
            );
        }
    }, [props.value]);

    return (
        <div ref={containerRef}></div>
    );
}