import React from "react";
import { Box, Card, CardBody, CardHeader, Flex, Text, Heading, LinkBox, Spacer, Image, VStack, HStack, Icon, Button, Switch, useToast, LinkOverlay } from "@chakra-ui/react";
import { FaArrowCircleRight, FaFile, FaGlobe, FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GrantScopesServiceHandler } from "../../../modules/services/account/grant-scopes-handler.service";
import { UserGetScopesToGrantResponse } from "../../../modules/models/responses/user/scopes/user-get-scopes-to-grant-response.model";
import { selectSignInActionUUIDSelector } from "../../../modules/models/selectors/signInSessionSelector";
import { useSearchParams } from "react-router-dom";
import { setActionUUID } from "../../../modules/stores/signInSessionSlice";
import { Trans, useTranslation } from "react-i18next";

export default function GrantScopesView() {
    const { t } = useTranslation();
    const actionUUID = useSelector(selectSignInActionUUIDSelector)
    const dispatch = useDispatch()
    const toast = useToast()
    const [scopesToGrant, setScopesToGrant] = useState<UserGetScopesToGrantResponse>({
        clientName: "",
        scopes: [],
        clientUri: "",
        tosUri: "",
        logoUri: "",
        policyUri: "",
    })
    const [searchParams] = useSearchParams();
    const [toggledStates, setToggledStates] = useState(new Array(scopesToGrant.scopes.length).fill(false));

    const handleToggle = (index: number) => {
        const newToggledStates = [...toggledStates];
        newToggledStates[index] = !newToggledStates[index];
        setToggledStates(newToggledStates);
    };


    useEffect(() => {
        const action_uuid = searchParams.get('action_uuid');
        const currentUUID = action_uuid ? action_uuid : actionUUID;
        dispatch(setActionUUID(currentUUID))
        GrantScopesServiceHandler.build().getScopes(currentUUID, dispatch, toast, (res: UserGetScopesToGrantResponse) => {
            setScopesToGrant({
                ...res
            })
            const toggled = res.scopes.map((scope, index) => {
                return scope.alreadyGranted;
            });
            setToggledStates(toggled)
        });
    }, []);

    return (
        <form action="/api/v1/user/grant-scope/grant-scope" method="post" encType="application/x-www-form-urlencoded">
            <Flex
                width={"100%"}
                gap={4}
                m={8}
            >
                <input type="hidden" name="actionUUID" value={actionUUID} />
                <input type="hidden" name="clientID" value={process.env.REACT_APP_CLIENT_ID} />
                {
                    scopesToGrant.scopes.map((scope, index) => {
                        return (
                            <>
                                <input key={`name_${index}`} type="hidden" name={`scopes[${index}].scopeName`} value={scope.scopeFullName} />
                                <input key={`granted_${index}`} type="hidden" name={`scopes[${index}].granted`} value={
                                    `${toggledStates[index] ? "true" : "false"}`
                                } />
                            </>
                        );
                    })
                }
                <Spacer />
                <Card maxW='2xl' width={"2xl"} bg={"linear-gradient(to right, #252799, #712be2)"} color="white" py={8} pb={12}>
                    <CardHeader>
                        <Box py={4}>
                            <Flex
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {scopesToGrant.logoUri && <Image
                                    src={scopesToGrant.logoUri}
                                    alt={scopesToGrant.clientName}
                                    width={"125px"}
                                    objectFit="cover"
                                />}
                            </Flex>
                            <Heading textAlign={"center"} size={"2xl"} mt={8}>{scopesToGrant.clientName}</Heading>
                        </Box>
                    </CardHeader>
                    <CardBody mt={0} pt={0}>
                        <Flex>
                            <Spacer />
                            <Box width={"md"} >
                                <Text fontSize={"22px"}>
                                    <Trans
                                        i18nKey="grant_access_to"
                                        components={{
                                            1: <strong />
                                        }}
                                        values={{ clientName: scopesToGrant.clientName }} />
                                </Text>
                                {scopesToGrant.scopes.map((scope, index) => (
                                    <HStack marginTop={"auto"} marginBottom={"auto"} px={4} py={2} pt={4}>
                                        <Box>
                                            <Text fontSize="16px" fontWeight={"bold"}>{scope.scopeFullName}</Text>
                                            <Text fontSize="12px">{scope.scopeDescription}</Text>
                                        </Box>
                                        <Spacer />
                                        <Switch
                                            colorScheme="green"
                                            isChecked={toggledStates[index]}
                                            onChange={() => handleToggle(index)}
                                        />
                                    </HStack>
                                ))}
                            </Box>
                            <Spacer />
                        </Flex>

                        {scopesToGrant.clientUri &&
                            <Flex mt={8}>
                                <Spacer />
                                <LinkBox as='article' maxW='md' width={"md"} rounded='md' px={2} mt={0} >
                                    <Flex>
                                        <VStack marginTop={"auto"} marginBottom={"auto"}>
                                            <HStack>
                                                <LinkOverlay href={scopesToGrant.clientUri} target="_blank" rel="noopener noreferrer">
                                                    <Icon as={FaGlobe} color={"white"} fontSize="24px" mr={2} />
                                                    <Box>
                                                        <Text fontSize="16px">{t('website', 'Website')}</Text>
                                                        <Text fontSize="12px">{scopesToGrant.clientUri}</Text>
                                                    </Box>
                                                </LinkOverlay>
                                            </HStack>
                                        </VStack>

                                        <Spacer />
                                        <Button style={{
                                            marginTop: "auto",
                                            marginBottom: "auto"
                                        }}
                                            mx={0}
                                            px={0}
                                            colorScheme='white'
                                            variant={"ghost"}>
                                            <Icon as={FaArrowCircleRight} ml={2} />
                                        </Button>
                                    </Flex>
                                </LinkBox>
                                <Spacer />
                            </Flex>
                        }
                        {scopesToGrant.tosUri &&
                            <Flex mt={4}>
                                <Spacer />
                                <LinkBox as='article' maxW='md' width={"md"} rounded='md' px={2} mt={0} >
                                    <Flex>
                                        <VStack marginTop={"auto"} marginBottom={"auto"}>
                                            <HStack>
                                                <LinkOverlay href={scopesToGrant.tosUri} target="_blank" rel="noopener noreferrer">
                                                    <Icon as={FaFile} color={"white"} fontSize="24px" mr={2} />
                                                    <Box>
                                                        <Text fontSize="16px">{t('terms_of_service', 'Terms of Service')}</Text>
                                                        <Text fontSize="12px">{scopesToGrant.tosUri}</Text>
                                                    </Box>
                                                </LinkOverlay>
                                            </HStack>
                                        </VStack>

                                        <Spacer />
                                        <Button style={{
                                            marginTop: "auto",
                                            marginBottom: "auto"
                                        }}
                                            mx={0}
                                            px={0}
                                            colorScheme='white'
                                            variant={"ghost"}>
                                            <Icon as={FaArrowCircleRight} ml={2} />
                                        </Button>
                                    </Flex>
                                </LinkBox>
                                <Spacer />
                            </Flex>
                        }

                        {scopesToGrant.policyUri && <Flex mt={4}>
                            <Spacer />
                            <LinkBox as='article' maxW='md' width={"md"} rounded='md' px={2} mt={0} >
                                <Flex>
                                    <VStack marginTop={"auto"} marginBottom={"auto"}>
                                        <HStack>
                                            <LinkOverlay href={scopesToGrant.policyUri} target="_blank" rel="noopener noreferrer">
                                                <Icon as={FaLock} color={"white"} fontSize="24px" mr={2} />
                                                <Box>
                                                    <Text fontSize="16px">{t('privacy_policy', 'Privacy Policy')}</Text>
                                                    <Text fontSize="12px">{scopesToGrant.policyUri}</Text>
                                                </Box>
                                            </LinkOverlay>
                                        </HStack>
                                    </VStack>

                                    <Spacer />
                                    <Button style={{
                                        marginTop: "auto",
                                        marginBottom: "auto"
                                    }}
                                        mx={0}
                                        px={0}
                                        colorScheme='white'
                                        variant={"ghost"}>
                                        <Icon as={FaArrowCircleRight} ml={2} />
                                    </Button>
                                </Flex>
                            </LinkBox>
                            <Spacer />
                        </Flex>}

                        <Flex mt={8}>
                            <Spacer />
                            <Button
                                mt={4}
                                width={"md"}
                                variant="solid"
                                colorScheme="orange"
                                className="form-button"
                                py={6}
                                type="submit">
                                {t('grant_permissions', 'Grant Permissions')}
                            </Button>
                            <Spacer />
                        </Flex>
                    </CardBody>
                </Card>
                <Spacer />
            </Flex>
        </form>
    )
}