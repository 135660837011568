import React from "react";
import { useEffect, useState } from "react";
import {
    Box,
    Flex,
    Button,
    Text,
    Grid,
    GridItem,
    IconButton,
    Tooltip, // Add Select from Chakra UI
} from "@chakra-ui/react";

import '../../../styles/forms.scss';
import { useDispatch, useSelector } from "react-redux";
import { selectUserBasicInfoSelector } from "../../../modules/models/selectors/userRegistrationSelectors";
import { updateOnRegisterBaseInfo } from "../../../modules/stores/userRegistrationSlice";
import { CheckCircleIcon, RepeatIcon, WarningTwoIcon } from "@chakra-ui/icons";
import BaseFormField from "../../../components/Forms/BaseFormField";
import { useFormik } from 'formik';
import { SignUpService } from "../../../modules/services/sign-up/sign-up.service";
import { BaseUserResponseModel } from "../../../modules/models/responses/baseUserResponse.model";
import { CalendarHelper } from "../../../modules/helpers/calendarHelper";
import { SignUpBaseInfoRequestModel } from "../../../modules/models/requests/authorization/sign-up/signUpBaseInfoRequest.model";
import DateField from "../../../components/Forms/DateField";
import { HttpValidatorService } from "../../../modules/services/http-validator.service";
import { useToast } from '@chakra-ui/react'
import { SignUpBaseInfoResponseModel } from "../../../modules/models/responses/authorization/sign-up/signUpBaseInfoResponse.model";
import { Dispatch } from "@reduxjs/toolkit";
import { FormCommonHandlerService } from "../formCommonHandler";
import { useTranslation } from "react-i18next";

function submitBaseUserInfo(values: any, dispatch: Dispatch, toast: any) {
    const request: SignUpBaseInfoRequestModel = {
        ...values,
        birthDate: CalendarHelper.build().getFullDate(values.year, values.month, values.day)
    };

    SignUpService.build().registerBaseInfo(request).then((res) => {
        HttpValidatorService.build().validateResponse(res, toast, (data: SignUpBaseInfoResponseModel) => {
            console.log(data);
            dispatch(updateOnRegisterBaseInfo({
                baseInfo: request,
                baseInfoResponse: data
            }))
        }, () => {
            console.log("ERROR")
        }, dispatch);
    }, (err) => {
        console.log("ERRPR", err)
    })
}

export default function BaseUserInfoForm() {
    const [userNameVerified, setUserNameVerified] = useState(false);
    const [userNameAvailableMessage, setUserNameAvailableMessage] = useState('');

    const userBaseInfo = useSelector(selectUserBasicInfoSelector)
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: { ...userBaseInfo },
        validationSchema: SignUpBaseInfoRequestModel.validationSchema(),
        onSubmit: (values) => {
            FormCommonHandlerService.build().setFormTouched(formik)

            if (!formik.isValid) return;
            submitBaseUserInfo(values, dispatch, toast);
        },
    });

    const toast = useToast()

    const checkUsernameAvailability = () => {
        SignUpService.build().checkUsername(formik.values.userName).then((res) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: BaseUserResponseModel) => {
                console.log(data);
                setUserNameVerified(data.errorCode == 0);
                setUserNameAvailableMessage(data.message ?? "");
            }, () => {
                console.log("ERROR")
            }, dispatch);
        }, (err) => {
            console.log(err)
        })
    };

    useEffect(() => {
        if (!formik.errors.userName && formik.touched.userName) {
            checkUsernameAvailability();
        }
    }, [formik.errors.userName, formik.touched.userName, formik.values.userName]);


    return (
        <form onSubmit={formik.handleSubmit}>
            <Box display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                width={"100%"}>
                <Text className="form-container-heading">{t('create_account', "Create Your Account")}</Text>
                <BaseFormField
                    actionButton={userNameVerified ?
                        <Tooltip hasArrow label={userNameAvailableMessage} bg='green.600'>
                            <IconButton
                                variant={'ghost'}
                                colorScheme="green"
                                aria-label="success-icon"
                                className="p4"
                                onClick={() => {
                                }}
                                icon={<CheckCircleIcon
                                    w={6} h={6}
                                />}
                            >
                            </IconButton>
                        </Tooltip> :
                        <Flex>
                            <Tooltip hasArrow label={userNameAvailableMessage} bg='red.600'>
                                <IconButton
                                    variant={'ghost'}
                                    colorScheme="red"
                                    aria-label="error-icon"
                                    className="error-icon-button p2"
                                    onClick={() => {
                                    }}
                                    icon={<WarningTwoIcon
                                        className="error-icon"
                                        w={5} h={5}
                                    />}
                                >
                                </IconButton>
                            </Tooltip>
                            <IconButton
                                variant={'ghost'}
                                aria-label="refresh-icon"
                                className="p2"
                                onClick={() => {
                                    checkUsernameAvailability();
                                }}
                                icon={<RepeatIcon
                                    className="repeat-icon"
                                    w={5} h={5}
                                />}
                            >
                            </IconButton>
                        </Flex>
                    }
                    inputProps={{
                        type: "text",
                        maxLength: undefined,
                        minLength: undefined
                    }}
                    formik={formik}
                    fieldName="userName"
                    fieldNameLabel={t('username', "Username")}
                />
                <Grid templateColumns="repeat(2, 1fr)" gap={2} width={"100%"}>
                    <GridItem colSpan={1}>
                        <BaseFormField
                            actionButton={undefined}
                            inputProps={{
                                type: "text",
                                maxLength: undefined,
                                minLength: undefined
                            }}
                            formik={formik}
                            fieldName="firstName"
                            fieldNameLabel={t('first_name', "First Name")}
                        />
                    </GridItem>
                    <GridItem colSpan={1}>
                        <BaseFormField
                            actionButton={undefined}
                            inputProps={{
                                type: "text",
                                maxLength: undefined,
                                minLength: undefined
                            }}
                            formik={formik}
                            fieldName="lastName"
                            fieldNameLabel={t('last_name', "Last Name")}
                        />
                    </GridItem>
                </Grid>

                <DateField formik={formik} />

                <BaseFormField
                    actionButton={undefined}
                    inputProps={{
                        type: "email",
                        maxLength: undefined,
                        minLength: undefined
                    }}
                    formik={formik}
                    fieldName="email"
                    fieldNameLabel={t('email', "Email Address")}
                />

                <Button
                    variant="solid"
                    className="form-button"
                    py={6}
                    mt={4}
                    type="submit"
                >
                    {t('verify_email', "Verify Email Address")}
                </Button>
            </Box>
        </form>
    );
}
