import React, { useEffect, useState } from "react";
import EmailPreviewCredentialsView from "./Preview/EmailPreviewCredentialsView";
import EmailManageCredentialView from "./EmailManageCredentialView";
import { UserEmailCredentialModel } from "../../../../../modules/models/other/user/user-email-credential.model";

export enum EmailCredentialViewState {
    PREVIEW,
    ADD,
    MANAGE
}

export default function EmailCredentialsView() {
    const [state, setState] = useState(EmailCredentialViewState.PREVIEW);
    const [managedCredential, setManagedCredential] = useState<UserEmailCredentialModel>();

    return (
        <>
            {
                state === EmailCredentialViewState.PREVIEW &&
                <EmailPreviewCredentialsView
                    setStage={(stage: EmailCredentialViewState) => { setState(stage); }}
                    manageEmailAddress={(credential: UserEmailCredentialModel) => {
                        setState(EmailCredentialViewState.MANAGE);
                        setManagedCredential(credential);
                    }}
                />
            }
            {
                state === EmailCredentialViewState.MANAGE && !!managedCredential &&
                <EmailManageCredentialView
                    credential={managedCredential}
                    goBack={() => {
                        setState(EmailCredentialViewState.PREVIEW)
                    }}
                />
            }
        </>
    );
}