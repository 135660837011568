import axios from "axios";
import { getDefaultIdentityApiUrlWithVersion } from "../general-settings.service";

export class GrantScopesService {
    static build() {
        return new GrantScopesService();
    }

    getScopes(actionUUID: string) {
        return axios.get(`${getDefaultIdentityApiUrlWithVersion()}/user/grant-scope/get-scopes?action_uuid=${actionUUID}`);
    }
}