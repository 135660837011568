import { createSelector } from 'reselect';
import { UserRegistrationStateModel } from '../stores/userRegistrationState.model';

const selectUserRegistration = (state: any) => state.userRegistration;

export const selectUserBasicInfoSelector = createSelector(
    [selectUserRegistration],
    (user: UserRegistrationStateModel) => user.basicInfo
);

export const selectUserBasicInfoResponseSelector = createSelector(
    [selectUserRegistration],
    (user: UserRegistrationStateModel) => user.basicInfoResponse
);

export const userEmailVerficationRequestSelector = createSelector(
    [selectUserRegistration],
    (user: UserRegistrationStateModel) => user.emailVerificationRequest
);

export const userPasswordSelector = createSelector(
    [selectUserRegistration],
    (user: UserRegistrationStateModel) => user.setPasswordRequest
);

export const finalRegistrationEmail = createSelector(
    [selectUserRegistration],
    (user: UserRegistrationStateModel) => user.registrationEmailAddress
);

export const selectRegistrationProgress = createSelector(
    [selectUserRegistration],
    (user: UserRegistrationStateModel) => {
        // The function should return something. It looks like you want to return an object.
        return {
            currentStepIndex: user.currentStepIndex,
            currentStep: user.currentStep,
            registrationFinished: user.registrationFinished
        };
    }
);