import React from "react";
import {
    Flex,
    Spacer,
    useToast,
} from '@chakra-ui/react'
import SettingsTabView from './SettingsTabView';
import ContactInformationTabView from './ContactInformationTabView';
import ProfileTabView from './ProfileTabView';
import { useEffect } from 'react';
import { ProfileService } from '../../../../modules/services/account/profile.service';
import { useDispatch } from 'react-redux';
import { HttpValidatorService } from '../../../../modules/services/http-validator.service';
import { UserDashboardResponseModel } from '../../../../modules/models/responses/user/user-dashboard-response.model';
import { setDashboard } from '../../../../modules/stores/userProfileSlice';

export default function Dashboard() {
    const dispatch = useDispatch()
    const toast = useToast()


    useEffect(() => {
        console.log("Dashboard")
        ProfileService.build().dashboard().then((res: any) => {
            HttpValidatorService.build().validateResponse(res, toast, (data: UserDashboardResponseModel) => {
                console.log(data);
                dispatch(setDashboard({
                    ...data,
                    userProfile: {
                        ...data.userProfile,
                        birthDate: data.userProfile.birthDate
                    }
                }))
            }, () => {
                console.log("ERROR")
            }, dispatch);
        }, (err: any) => {
            console.log(err)
        })
    }, [])

    return (
        <Flex
            width={"100%"}
            gap={4}
            m={8}
        >
            <Spacer />
            <ProfileTabView />
            <ContactInformationTabView />
            <SettingsTabView />
            <Spacer />
        </Flex>
    )
}