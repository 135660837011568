import React from "react";
import {
    Box,
    Button,
    Link,
    Text,
    useToast
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import '../../../styles/forms.scss';
import { userPasswordSelector } from "../../../modules/models/selectors/userRegistrationSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { SignUpSetPasswordRequestModel } from "../../../modules/models/requests/authorization/sign-up/signUpSetPassword.model";
import BaseFormField from "../../../components/Forms/BaseFormField";
import { SignUpHandlerService } from "../../../modules/services/sign-up/sign-up.handler.service";
import { FormCommonHandlerService } from "../formCommonHandler";
import { useTranslation } from "react-i18next";

export default function PasswordForm() {
    const userSetPasswordRequest = useSelector(userPasswordSelector)
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const toast = useToast()

    const formik = useFormik({
        initialValues: { ...userSetPasswordRequest },
        validationSchema: SignUpSetPasswordRequestModel.validationSchema(),
        onSubmit: (values) => {
            FormCommonHandlerService.build().setFormTouched(formik)
            if (!formik.isValid) return;

            SignUpHandlerService.build().setPassword(values, dispatch, toast);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                width={"100%"}>
                <Text className="form-container-heading">{t('create_password', "Create Your Password")}</Text>
                <Text textAlign={"center"} mb={4}>
                    <CheckCircleIcon color="green.500" fontSize="1.5rem" mb={1} mx={2} /> <strong>{t('its_you', "It's you!")}</strong> {t('email_successfully_verified', 'Your emails has been successfully verified.')}
                </Text>
                <Text textAlign={"center"} mb={8}>
                    {t('password_purpose_com', "Your password provides you with sign in access to your account and secures your data.")}
                </Text>
                <BaseFormField
                    actionButton={undefined}
                    inputProps={{
                        type: "password"
                    }}
                    formik={formik}
                    fieldName="password"
                    fieldNameLabel={t('password', "Password")}
                />
                <BaseFormField
                    actionButton={undefined}
                    inputProps={{
                        type: "password"
                    }}
                    formik={formik}
                    fieldName="confirmPassword"
                    fieldNameLabel={t('confirm_passowrd', "Confirm Password")}
                />

                <Button
                    variant="solid"
                    className="form-button"
                    py={6}
                    type="submit"
                >
                    {t('register', "Register")}
                </Button>
            </Box>
            <Box mt={8} textAlign="center">
                <Text>
                    {t('sign_agree_com', 'By signing up you agree to our')}{" "}
                    <Link color="orange">{t('terms', "Terms")}</Link> {t('and', 'and')}{" "}
                    <Link color="orange">{t('conditions_of_use', "Conditions of Use")}</Link>.
                </Text>
            </Box>
        </form>
    );
}