import { Dispatch } from "react";
import { GlobalResponseModel } from "../../models/responses/globalResponse.model";
import { SetPasswordRequestModel } from "../recover/recover.service";
import { ChangePasswordService } from "./change-password.service";
import { HttpValidatorService } from "../http-validator.service";
import { VerificationActionResponseModel } from "../../models/responses/authorization/verifyActionResponse.model";
import { VerifyActionRequestModel } from "../../models/requests/authorization/verifyActionRequest.model";

export class ChangePasswordHandlerService {
    static build() {
        return new ChangePasswordHandlerService();
    }

    setPassword(request: SetPasswordRequestModel, dispatch: Dispatch<any>, toast: any, 
        handle: (data: GlobalResponseModel) => void = (data) => {}) {
            ChangePasswordService.build().setPassword(request).then((resp) => {
                HttpValidatorService.build().validateResponse(resp, toast, (data: GlobalResponseModel) => {
                    handle(data);
                }, () => {
                    console.log("ERROR")
                }, dispatch)
            }, (err: any) => {
                console.log("ERRPR", err)
            });
        }

    requestVerify(dispatch: Dispatch<any>, toast: any, 
        handle: (data: VerificationActionResponseModel) => void = (data) => {}) {
            ChangePasswordService.build().requestVerify().then((resp) => {
                HttpValidatorService.build().validateResponse(resp, toast, (data: VerificationActionResponseModel) => {
                    handle(data);
                }, () => {
                    console.log("ERROR")
                }, dispatch)
            }, (err: any) => {
                console.log("ERRPR", err)
            });
        }   
            
    refreshVerify(actionUUID: string, dispatch: Dispatch<any>, toast: any, 
        handle: (data: VerificationActionResponseModel) => void = (data) => {}) {
            ChangePasswordService.build().refreshVerify(actionUUID).then((resp) => {
                HttpValidatorService.build().validateResponse(resp, toast, (data: VerificationActionResponseModel) => {
                    handle(data);
                }, () => {
                    console.log("ERROR")
                }, dispatch)
            }, (err: any) => {
                console.log("ERRPR", err)
            });
        }  

    verify(request: VerifyActionRequestModel, dispatch: Dispatch<any>, toast: any, 
        handle: (data: VerificationActionResponseModel) => void = (data) => {}) {
            ChangePasswordService.build().verify(request).then((resp) => {
                HttpValidatorService.build().validateResponse(resp, toast, (data: VerificationActionResponseModel) => {
                    handle(data);
                }, () => {
                    console.log("ERROR")
                }, dispatch)
            }, (err: any) => {
                console.log("ERRPR", err)
            });
        }
}