import React, { useEffect } from "react";
import './App.css';
import SignInView from './views/Authorization/SignIn/SignInView';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './views/Home/HomeView';
import SignUpView from './views/Authorization/SignUp/SignUpView';
import RecoverPasswordView from './views/Authorization/RecoverPassword/RecoverPasswordView';
import { setGlobalClientID } from './modules/services/general-settings.service';
import { useSelector } from 'react-redux';
import PrivateRoute, { PublicRouteSignIn, PublicRouteWithRedirectToDashboard } from './components/Router/PrivateRouter';
import { selectUserLoggedIn } from './modules/models/selectors/signInSessionSelector';
import Profile from './views/Authorization/Profile/Profile';
import Dashboard from './views/Authorization/Profile/Dashboard/Dashboard';
import SecurityView from './views/Authorization/Profile/Security/Security';
import AntiPhisingTokenEmailView from './views/Authorization/Profile/Security/AntiPhishingTokenEmailView';
import TOTPSetup from './views/Authorization/Profile/Security/TOTPSetup/TOTPSetup';
import GrantScopesView from './views/Authorization/OAuth/GrantScopes';
import RecoverPasswordStep2View from "./views/Authorization/RecoverPassword/RecoverPasswordStep2";
import RecoverPasswordBaseView from "./views/Authorization/RecoverPassword/RecoverPasswordBaseView";

import i18n from './i18n';
import { useTranslation, Trans } from 'react-i18next';
import EmailCredentialsView from "./views/Authorization/Profile/Security/AntiPhishing/EmailCredentialsView";

setGlobalClientID();

function App() {
  const userLoggedIn = useSelector(selectUserLoggedIn)

  // setTimeout(() => {
  //   console.log(t('latest:home'));
  // }, 4000);

  return (
    <Router>
      <Navbar />
      <div style={{ display: "flex", flexDirection: "column", margin: "0rem" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/sign-up"
            element={
              <PublicRouteWithRedirectToDashboard
                element={<SignUpView />}
                isAuthenticated={!userLoggedIn}
                redirectPath="/dashboard"
              />
            }
          />
          <Route
            path="/sign-in"
            element={
              <PublicRouteSignIn
                element={<SignInView />}
                isAuthenticated={!userLoggedIn}
                redirectPath="/dashboard"
                authPath="/grant_scopes"
              />
            }
          />
          <Route
            path="/recover-password"
            element={
              <PublicRouteWithRedirectToDashboard
                element={<RecoverPasswordBaseView />}
                isAuthenticated={!userLoggedIn}
                redirectPath="/dashboard"
              />
            }
          />
          <Route
            path="/recover"
            element={
              <PublicRouteWithRedirectToDashboard
                element={<RecoverPasswordStep2View />}
                isAuthenticated={!userLoggedIn}
                redirectPath="/dashboard"
              />
            }
          />
          <Route path="/grant_scopes"
            element={
              <PrivateRoute
                element={<GrantScopesView />}
                isAuthenticated={userLoggedIn}
                redirectPath="/sign-in"
              />
            } />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                element={<Profile />}
                isAuthenticated={userLoggedIn}
                redirectPath="/sign-in"
              />
            }>
            <Route path="" element={<Dashboard />} />
            <Route path="security" element={<SecurityView />} />
            <Route path='security/totp' element={<TOTPSetup />} />
            <Route path="security/email" element={<EmailCredentialsView />} />
          </Route>
        </Routes>
      </div>
      <Footer />
    </Router>

  );
}

export default App;
