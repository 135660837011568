import React from "react";
import {
    Box,
    Button,
    Text,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import '../../../styles/forms.scss';
import { useSelector } from "react-redux";
import { finalRegistrationEmail } from "../../../modules/models/selectors/userRegistrationSelectors";
import { Trans, useTranslation } from "react-i18next";

export default function AfterSignUpView() {
    const registrationEmail = useSelector(finalRegistrationEmail)
    const { t } = useTranslation();

    return (
        <Box
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            width={"100%"}>
            <Text className="form-container-heading" textAlign={"center"}>{t('success', "Success")}</Text>
            <Text textAlign={"center"} mb={4} fontWeight={"bold"}>
                <CheckCircleIcon color="green.500" fontSize="1.5rem" mb={0} mx={2} />
                {t('setup_success', ' Everything has been setup successfully.')}
            </Text>
            <Text textAlign={"center"}>
                <Trans
                    i18nKey="confirm_email_on_way"
                    components={{
                        1: <strong />
                    }}
                    values={{
                        email: registrationEmail
                    }}
                    defaultValue="A confirmation email is on its way to <1>{{email}}</1>!"
                />
            </Text>
            <Text textAlign={"center"} mb={8}>
                {t('on_register_email_arrive', "Once it arrives, you're all set to sign in and start enjoying your account.")}
            </Text>

            <Button
                variant="solid"
                className="form-button"
                py={6}
                as={RouterLink} to="/sign-in">
                {t('sign_in', "Sign In")}
            </Button>
        </Box>
    );
}