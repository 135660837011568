import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BaseFormField from "../../../../../components/Forms/BaseFormField";
import { useFormik } from "formik";
import { FormCommonHandlerService } from "../../../formCommonHandler";
import { Trans, useTranslation } from "react-i18next";
import { PasswordValidator } from "../../../../../modules/models/requests/authorization/sign-up/signUpSetPassword.model";

export interface SetAntiPhishingCodeModalProps {
    isOpen: boolean,
    email: string,
    onClose: () => void,
    onSet: (password: string) => void
}

export default function SetAntiPhishingCodeModal(props: SetAntiPhishingCodeModalProps) {
    const finalRef = React.useRef(null)
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            password: ""
        },
        validationSchema: PasswordValidator.validationSchema(),
        onSubmit: (values) => {
            FormCommonHandlerService.build().setFormTouched(formik)

            if (!formik.isValid) return;

            props.onSet(values.password);
            formik.resetForm();
        },
    });

    return (
        <>
            <Modal finalFocusRef={finalRef} isOpen={props.isOpen} onClose={props.onClose} size="xl">
                <ModalOverlay />
                <form onSubmit={formik.handleSubmit}>
                    <ModalContent bg={"linear-gradient(to right, #252799, #712be2)"} color="white" p={4}>
                        <ModalHeader>
                            <Trans i18nKey="set_antiphishing_token"
                                defaultValue={"Set Anti-Phishing Token"} />
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>

                            <Text textAlign={"justify"} mb={6}>
                                <Trans i18nKey="set_antiphishing_token_to_protect"
                                    defaultValue={"Set anti-phishing code to protect your email address <1>{{email}}</1> from phishing attacks."}
                                    values={{ email: props.email }}
                                    components={[<strong></strong>]}
                                />
                            </Text>

                            <BaseFormField
                                actionButton={undefined}
                                inputProps={{
                                    type: "password",
                                    maxLength: undefined,
                                    minLength: undefined
                                }}
                                formik={formik}
                                fieldName="password"
                                fieldNameLabel={t('anti_phishing_code', "Anti-Phishing Code")}
                            />

                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' variant='solid' type="submit">
                                <Trans i18nKey="set_antiphishing_token"
                                    defaultValue={"Set Anti-Phishing Token"} />
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>
        </>
    );
}